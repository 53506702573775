import { Box, Button, Image, Text } from '@chakra-ui/react'
import { ActionPostMessageNativeAppEnum } from 'constants/postMessage'
import { webviewPostMessage } from 'utils/postMessage'

type OrderSummaryType = {
  title: string
  message: string
  image?: string
}

const OrderSummary = ({ title, message, image }: OrderSummaryType) => {
  const handleBackToMobile = () => {
    webviewPostMessage({
      action: ActionPostMessageNativeAppEnum.WEBVIEW_NAVIGATE_BACK,
    })
  }

  return (
    <Box
      w="100%"
      h="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Image src={image} w="300px" mb="24px" />
      <Box textAlign="center" px="16px">
        <Text fontWeight={800} fontSize="20px" lineHeight="28px">
          {title}
        </Text>
      </Box>

      <Box textAlign="center" px="16px" mb="16px">
        <Text
          fontWeight={600}
          fontSize="14px"
          lineHeight="20px"
          color="#646464"
        >
          {message}
        </Text>
      </Box>
      <Button
        w="240px"
        variant="solid"
        colorScheme="blue"
        bg="#246EE5"
        onClick={handleBackToMobile}
      >
        <Text
          fontWeight={700}
          fontSize="16px"
          lineHeight="22px"
          color="#ffffff"
        >
          Kembali
        </Text>
      </Button>
    </Box>
  )
}

export default OrderSummary
