import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import 'leaflet-active-area'

type ActiveAreaProps = {
  recenter?: boolean
  animate?: boolean
}

type MapOptions = {
  setActiveArea: (
    active: string | HTMLElement,
    recenterMap?: boolean,
    animateMap?: boolean
  ) => void
}

const ActiveArea = ({ recenter, animate }: ActiveAreaProps) => {
  const map = useMap()

  useEffect(() => {
    const recenterMap = !!recenter
    const animateMap = !!(animate && recenterMap)

    ;(map as unknown as MapOptions).setActiveArea(
      'activeArea',
      recenterMap,
      animateMap
    )

    return () => {
      const originalContainer = map.getContainer()
      ;(map as unknown as MapOptions).setActiveArea(originalContainer)
    }
  }, [animate, map, recenter])

  return <div className="activeArea" />
}

export default ActiveArea
