/* eslint-disable no-console */

import { Polyline, useMap } from 'react-leaflet'
import { memo, useEffect, useRef, useState } from 'react'
import L from 'leaflet'
import {
  LeafletTrackingMarker,
  LeafletTrackingMarkerElement,
} from 'react-leaflet-tracking-marker'
import { driverIcon } from '../Icon'
import { DriverPopup } from '../Popup'
import {
  calculateDistance,
  checkIsOnRoutePath,
  DISTANCE_THRESHOLD_REROUTE,
  findClosestNodeOnRoute,
  findSegment,
  getPositionBeforeRouteStart,
  isMoreThanTwoMinutes,
} from '../utils'

type PositionType = [number, number]
export type DriverStateType = {
  position: PositionType
  prevPosition: PositionType
  duration: number
  route: PositionType[]
}

export type DriverTrackingMarkerRouteEnginePropsType = {
  driverPosition: PositionType
  driverId: number
  driverName: string
  driverStatus: string
  latestUpdate: number
  orderIdActive?: number
  duration?: number
  focusOnDriverAndDestination?: boolean
  destinationPosition?: PositionType
  showPolyline?: boolean
  isFromWebview?: boolean
  route?: PositionType[]
  isError?: boolean
  keepAtCenter?: boolean
  withMoveAnimation?: boolean
  onError?: (driverId: number, isError: boolean) => void
  onClick?: (driverId: number, driverStatus: string) => void
  reRouting?: (driverPosition: PositionType) => Promise<PositionType[]>
  onUpdate?: (driverState: DriverStateType) => void
}

let autoZoomTimeout: NodeJS.Timeout

const DriverTrackingMarkerRouteEngine = memo(
  ({
    driverPosition,
    driverId,
    driverName,
    driverStatus,
    duration = 4500,
    latestUpdate,
    orderIdActive,
    destinationPosition,
    focusOnDriverAndDestination,
    showPolyline,
    isFromWebview = false,
    isError = false,
    route = [],
    keepAtCenter,
    withMoveAnimation = true,
    onError,
    onClick,
    reRouting,
    onUpdate,
  }: DriverTrackingMarkerRouteEnginePropsType) => {
    const map = useMap()
    const markerRef = useRef<LeafletTrackingMarkerElement | null>(null)
    const moveMarkerTimeoutsRef = useRef<NodeJS.Timeout[]>([])
    const lastUpdateTimeoutsRef = useRef<NodeJS.Timeout | null>(null)
    const lastUpdateRef = useRef<number>(0)
    const [manualInteraction, setManualInteraction] = useState(false)
    const [zoom, setZoom] = useState(map.getZoom())
    const [driverPositionReal, setDriverPositionReal] =
      useState<PositionType>(driverPosition)
    const [currentorderIdActive, setCurrentOrderIdActive] =
      useState(orderIdActive)
    const [currentDriverStatus, setCurrentDriverStatus] = useState(driverStatus)

    const [driverState, setDriverState] = useState<DriverStateType>({
      position: driverPosition,
      prevPosition: driverPosition,
      duration,
      route,
    })

    const [startDriverMoveAnimation, setStartDriverMoveAnimation] =
      useState(false)
    const [isEndPointAnimation, setIsEndPointAnimation] = useState(false)

    const autoFitBoundsAndSetZoom = () => {
      if (
        !manualInteraction &&
        focusOnDriverAndDestination &&
        destinationPosition &&
        driverState.route.length
      ) {
        try {
          const bounds = new L.LatLngBounds(driverState.route)
          map.fitBounds(bounds, { padding: [50, 50] })
        } catch {
          //
        }
      }
    }

    const autoFollowDriver = () => {
      if (!manualInteraction && keepAtCenter) {
        map.setView([driverState.position[0], driverState.position[1]], 19, {
          animate: true,
        })
      }
    }

    const clearTimeouts = () => {
      if (moveMarkerTimeoutsRef.current) {
        moveMarkerTimeoutsRef.current.forEach(clearTimeout)
        moveMarkerTimeoutsRef.current = []
        clearTimeout(moveMarkerTimeoutsRef.current as never)
      }
    }

    const getNewRoute = async (position: PositionType) => {
      const response = reRouting ? await reRouting(position) : []
      return response
    }

    const moveMarker = async () => {
      setIsEndPointAnimation(false)
      const start = findClosestNodeOnRoute(route, driverState.position)
      const end = findClosestNodeOnRoute(route, driverPosition)

      let segmentRoute = findSegment(route, start, end)
      if (segmentRoute.length > 0) {
        const firstPoint = segmentRoute[0]
        if (
          firstPoint[0] === driverState.position[0] &&
          firstPoint[1] === driverState.position[1]
        ) {
          segmentRoute = segmentRoute.slice(1)
        }
      }

      const lastRoute = route[route.length - 1]
      const isEndRoute =
        segmentRoute.length === 1 &&
        segmentRoute[0][0] === lastRoute[0] &&
        segmentRoute[0][1] === lastRoute[1]
      const isOneSegment = segmentRoute.length === 1
      const isNoSegment = segmentRoute.length === 0

      if (isEndRoute) {
        // console.log('| END ROUTE |')

        setDriverState((prev) => ({
          position: lastRoute,
          prevPosition: prev.position,
          duration: 1000,
          route: [],
        }))
      } else if (isOneSegment) {
        // console.log('HAS 1 SEGMENT')
        const newDuration = duration / 2

        setDriverState((prev) => {
          const pointIndex = route.indexOf(segmentRoute[0] as [number, number])

          return {
            position: segmentRoute[0],
            prevPosition: prev.position,
            duration: newDuration,
            route: pointIndex !== -1 ? route.slice(pointIndex) : prev.route,
          }
        })

        setTimeout(() => {
          setIsEndPointAnimation(true)
        }, newDuration + 100)
      } else if (isNoSegment) {
        // console.log('NO SEGMENT')
      } else {
        // console.log('ANIMATE SEGMENT')
        let timeoutId = null
        let totalDuration = 1

        segmentRoute.forEach((point, index) => {
          const pointIndex = route.indexOf(point as [number, number])
          const newDuration = duration / segmentRoute.length

          if (index === 0) {
            setDriverState((prev) => ({
              position: point,
              prevPosition: prev.position,
              duration: newDuration,
              route: pointIndex !== -1 ? route.slice(pointIndex) : prev.route,
            }))
          } else if (index !== segmentRoute.length - 1) {
            totalDuration += newDuration

            timeoutId = setTimeout(() => {
              // console.log(
              //   'ANIMATE POINT',
              //   index,
              //   point,
              //   newDuration,
              //   totalDuration
              // )
              setDriverState((prev) => ({
                position: point,
                prevPosition: prev.position,
                duration: newDuration,
                route: pointIndex !== -1 ? route.slice(pointIndex) : prev.route,
              }))
            }, totalDuration)

            if (moveMarkerTimeoutsRef.current) {
              moveMarkerTimeoutsRef.current.push(timeoutId)
            }
          } else {
            totalDuration += newDuration

            timeoutId = setTimeout(() => {
              // console.log(
              //   'ANIMATE LAST POINT',
              //   index,
              //   point,
              //   driverState.prevPosition,
              //   totalDuration
              // )
              setDriverState((prev) => ({
                position: point,
                prevPosition: prev.position,
                duration: newDuration,
                route: pointIndex !== -1 ? route.slice(pointIndex) : prev.route,
              }))
              moveMarkerTimeoutsRef.current = []
            }, totalDuration)

            setTimeout(() => {
              setIsEndPointAnimation(true)
            }, duration + 100)

            if (moveMarkerTimeoutsRef.current) {
              moveMarkerTimeoutsRef.current.push(timeoutId)
            }
          }

          // console.log('TOTAL DURATION', totalDuration)
        })
      }
    }

    useEffect(() => {
      if (markerRef.current) {
        markerRef.current.closePopup = () =>
          null as unknown as LeafletTrackingMarkerElement
        markerRef.current.openPopup()
      }
    }, [])

    useEffect(() => {
      if (latestUpdate) {
        lastUpdateRef.current = latestUpdate
        if (lastUpdateTimeoutsRef.current) {
          clearTimeout(lastUpdateTimeoutsRef.current)
        }

        const newTimeoutId = setTimeout(() => {
          if (onError) {
            onError(driverId, true)
          }
        }, 2 * 60 * 1000)

        lastUpdateTimeoutsRef.current = newTimeoutId
      }

      return () => {
        lastUpdateRef.current = 0
        if (lastUpdateTimeoutsRef.current) {
          clearTimeout(lastUpdateTimeoutsRef.current)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latestUpdate])

    useEffect(() => {
      const handleMarkerClick = () => {
        if (onClick) onClick(driverId, driverStatus)
      }

      if (markerRef.current) {
        const markerElement = markerRef.current.getElement()
        if (markerElement) {
          markerElement.addEventListener('click', handleMarkerClick)
        }
      }

      return () => {
        if (markerRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          const markerElement = markerRef.current.getElement()
          if (markerElement) {
            markerElement.removeEventListener('click', handleMarkerClick)
          }
        }
      }
    }, [driverId, driverStatus, onClick])

    useEffect(() => {
      const handleZoomStart = () => {
        setManualInteraction(true)
        clearTimeout(autoZoomTimeout)
      }

      const handleZoomEnd = (e: L.LeafletEvent) => {
        // eslint-disable-next-line no-underscore-dangle
        setZoom(e.target._zoom)
        autoZoomTimeout = setTimeout(() => {
          setManualInteraction(false)
        }, 10000)
      }

      const handleDragStart = () => {
        setManualInteraction(true)
        clearTimeout(autoZoomTimeout)
      }

      const handleDragEnd = () => {
        autoZoomTimeout = setTimeout(() => {
          setManualInteraction(false)
        }, 10000)
      }

      map.on('zoomstart', handleZoomStart)
      map.on('zoomend', handleZoomEnd)
      map.on('dragstart', handleDragStart)
      map.on('dragend', handleDragEnd)

      autoFitBoundsAndSetZoom()

      return () => {
        map.off('zoomstart', handleZoomStart)
        map.off('zoomend', handleZoomEnd)
        map.off('dragstart', handleDragStart)
        map.off('dragend', handleDragEnd)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(
      () => () => {
        clearTimeouts()
        clearTimeout(moveMarkerTimeoutsRef.current as never)
        // console.log('==== CLEAR TIMEOUT ====')
      },
      []
    )

    useEffect(() => {
      if (!manualInteraction) {
        autoFitBoundsAndSetZoom()
        autoFollowDriver()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driverState.position])

    useEffect(() => {
      if (orderIdActive !== currentorderIdActive) {
        setCurrentOrderIdActive(orderIdActive)
        if (reRouting) {
          // console.log('==== CHANGE ORDER ID ====')
          reRouting(driverPosition)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderIdActive])

    useEffect(() => {
      if (driverStatus !== currentDriverStatus) {
        setCurrentDriverStatus(driverStatus)
        if (reRouting) {
          // console.log('==== CHANGE DRIVER STATUS ====')
          reRouting(driverPosition)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driverStatus])

    useEffect(() => {
      const handleDriverPosition = async () => {
        if (driverPositionReal) {
          if (route.length) {
            const isOnRoutePath = checkIsOnRoutePath(driverPositionReal, route)
            if (!isOnRoutePath && reRouting) {
              // console.log('REROUTE NOT ON PATH')
              clearTimeouts()
              const newRoute = await getNewRoute(driverPosition)
              const closestNode = findClosestNodeOnRoute(
                newRoute,
                driverPositionReal
              )

              const distanceNode = calculateDistance(
                driverState.position,
                closestNode
              )

              const newDuration =
                distanceNode >= DISTANCE_THRESHOLD_REROUTE ? 1 : duration

              if (withMoveAnimation) {
                setDriverState((prev) => ({
                  position: closestNode,
                  prevPosition: prev.position,
                  duration: startDriverMoveAnimation ? newDuration : 1,
                  route: newRoute,
                }))

                autoFitBoundsAndSetZoom()
              } else {
                setDriverState((prev) => ({
                  position: driverPositionReal,
                  prevPosition: prev.position,
                  duration: startDriverMoveAnimation ? newDuration : 1,
                  route: newRoute,
                }))
              }
            } else {
              // console.log('MOVE DRIVER MARKER')
              if (withMoveAnimation && !moveMarkerTimeoutsRef.current.length) {
                moveMarker()
              } else {
                const distanceNode = calculateDistance(
                  driverState.position,
                  driverPositionReal
                )

                const needReroute = distanceNode >= DISTANCE_THRESHOLD_REROUTE
                if (needReroute) {
                  getNewRoute(driverPosition)
                }

                setDriverState((prev) => {
                  const closestNode = findClosestNodeOnRoute(
                    prev.route,
                    driverPositionReal
                  )
                  const pointIndex = route.indexOf(
                    closestNode as [number, number]
                  )
                  const newDuration = needReroute ? 1 : duration

                  return {
                    position: driverPositionReal,
                    prevPosition: prev.position,
                    duration: newDuration,
                    route:
                      pointIndex !== -1
                        ? [driverPositionReal, ...route.slice(pointIndex)]
                        : prev.route,
                  }
                })
                setTimeout(() => {
                  setIsEndPointAnimation(true)
                }, duration + 100)
              }
              if (!startDriverMoveAnimation) {
                // console.log('FIRST TIME ANIMATION STARTED')
                setStartDriverMoveAnimation(true)
              }
            }
          } else {
            // console.log('STATE FOR ALL DRIVER')

            const newDuration = !isMoreThanTwoMinutes(latestUpdate)
              ? duration
              : 1

            setDriverState((prev) => ({
              position: driverPositionReal,
              prevPosition: prev.position,
              duration: newDuration,
              route: focusOnDriverAndDestination ? prev.route : [],
            }))
          }
        }
      }

      handleDriverPosition()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driverPositionReal])

    useEffect(() => {
      if (
        driverPosition[0] !== driverPositionReal[0] &&
        driverPosition[1] !== driverPositionReal[1]
      ) {
        // console.log('=== NEW DRIVER POSITION ===')
        setDriverPositionReal(driverPosition)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driverPosition])

    useEffect(() => {
      if (route.length) {
        // console.log('NEW ROUTE')
        setDriverState((prev) => ({
          ...prev,
          duration: 1,
          route,
        }))
      }
    }, [route])

    useEffect(() => {
      if (onUpdate) {
        onUpdate(driverState)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driverState])

    return (
      <LeafletTrackingMarker
        ref={markerRef}
        icon={driverIcon(driverStatus, zoom, isFromWebview)}
        position={driverState.position}
        previousPosition={driverState.prevPosition}
        duration={driverState.duration}
        zIndexOffset={80000}
      >
        {!isFromWebview && (
          <DriverPopup
            driverName={driverName}
            latestUpdate={latestUpdate}
            zoom={zoom}
            isError={isError}
          />
        )}
        {showPolyline && driverState.route?.length && (
          <>
            <Polyline
              positions={
                isEndPointAnimation
                  ? driverState.route
                  : [
                      getPositionBeforeRouteStart(route, driverState.route[0]),
                      ...driverState.route,
                    ]
              }
              pathOptions={{
                color: isFromWebview ? '#0091ff' : '#0b2cf5',
                weight: 10,
                opacity: 1,
              }}
            />
            <Polyline
              positions={
                isEndPointAnimation
                  ? driverState.route
                  : [
                      getPositionBeforeRouteStart(route, driverState.route[0]),
                      ...driverState.route,
                    ]
              }
              pathOptions={{
                color: isFromWebview ? '#00A8FF' : '#0f53ff',
                weight: 6,
                opacity: 1,
              }}
            />
          </>
        )}
      </LeafletTrackingMarker>
    )
  }
)

export default DriverTrackingMarkerRouteEngine
