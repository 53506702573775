/* eslint-disable no-bitwise */

export const polylineDecode = (
  encoded: string,
  precision = 1e6
): [number, number][] => {
  const inv = 1.0 / precision
  const decoded: [number, number][] = []
  const previous: [number, number] = [0, 0]
  let i = 0

  while (i < encoded.length) {
    const ll: [number, number] = [0, 0]
    for (let j = 0; j < 2; j++) {
      let shift = 0
      let byte = 0x20

      while (byte >= 0x20) {
        byte = encoded.charCodeAt(i++) - 63
        ll[j] |= (byte & 0x1f) << shift
        shift += 5
      }

      ll[j] = previous[j] + (ll[j] & 1 ? ~(ll[j] >> 1) : ll[j] >> 1)
      previous[j] = ll[j]
    }

    decoded.push([ll[0] * inv, ll[1] * inv])
  }

  return decoded
}
