import { ActionPostMessageEnum } from 'constants/postMessage'
import { useEffect } from 'react'

interface Message<T> {
  action: ActionPostMessageEnum
  payload: T
}

const useReceiveMessage = <T>(
  action: ActionPostMessageEnum,
  callback: (data: Message<T>) => void
) => {
  useEffect(() => {
    const receiveMessage = (event: MessageEvent) => {
      const { origin, data } = event

      if (origin !== process.env.REACT_APP_ADMIN_URL) return

      if (data.action === action) {
        callback(data)
      }
    }

    window.addEventListener('message', receiveMessage)

    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [action, callback])
}

export default useReceiveMessage
