import Cookies from 'js-cookie'
import { NativeHeaders } from './webviewSession'

export const LOCATION_FEATURE_TYPE_COOKIE = '_ty'
export const TOKEN_TYPE_COOKIE = 'zvdgxvzb'

type LocationFeatureType = 'edit'

export const setLocationFeatureType = (val: LocationFeatureType) =>
  Cookies.set(LOCATION_FEATURE_TYPE_COOKIE, val)

export const removeLocationFeatureType = () =>
  Cookies.remove(LOCATION_FEATURE_TYPE_COOKIE)

export const getCookies = (key: string) => Cookies.get(key)
export const setCookies = (key: string, value: string) =>
  Cookies.set(key, value)
export const removeCookies = (key: string) => Cookies.remove(key)

export const getTokenCookies = () => getCookies(TOKEN_TYPE_COOKIE)
export const setTokenCookies = (value: string) =>
  setCookies(TOKEN_TYPE_COOKIE, value)
export const removeTokenCookies = () => removeCookies(TOKEN_TYPE_COOKIE)

interface ParsedCookies {
  [key: string]: string
}

export const parseCookie = (cookieString: string): ParsedCookies =>
  cookieString.split(';').reduce((cookies: ParsedCookies, cookie) => {
    const [name, value] = cookie.trim().split('=')
    cookies[name] = value
    return cookies
  }, {})

export const getNativeHeaders = () => {
  const cookies = parseCookie(document.cookie)
  const nativeHeaders: NativeHeaders | null = cookies?.nativeHeaders
    ? JSON.parse(cookies.nativeHeaders)
    : null

  return nativeHeaders
}
