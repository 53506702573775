import {
  initialWebviewSessionProps,
  WebviewSessionPropsType,
} from 'utils/webviewSession'

export type AuthSlice = {
  isAuthenticated: boolean
  userData: {
    authorities: { name: string }[]
    [key: string]: unknown
  }
  webviewSessionProps: WebviewSessionPropsType
  setUserData: (userData: AuthSlice['userData']) => void
  setIsAuthenticated: (isAuthenticated: boolean) => void
  setWebviewSessionProps: (payload: WebviewSessionPropsType) => void
}

export const createAuthSlice: StoreSlice<AuthSlice> = (set) => ({
  isAuthenticated: false,
  userData: {
    authorities: [],
  },
  webviewSessionProps: initialWebviewSessionProps,
  setIsAuthenticated: (isAuthenticated) => {
    set((state) => {
      state.isAuthenticated = isAuthenticated
    })
  },
  setUserData: (userData) => {
    set((state) => {
      state.userData = userData
    })
  },
  setWebviewSessionProps: (payload) => {
    set((state) => ({
      webviewSessionProps: {
        ...state.webviewSessionProps,
        ...payload,
      },
    }))
  },
})
