import { useEffect } from 'react'
import { useMqttStore } from 'store/mqtt'

interface Message<T> {
  topic: string
  meta: {
    domain: string
    locationType: string
    locationId: number
    topicKey: string
    id: number
  }
  payload: T
  userProperties: {
    appVersion: string
    deviceManufacture: string
    deviceModel: string
    driverId: string
    driverName: string
    osVersion: string
  }
}

const useMqttSubcribtion = <T>(callback: (data: Message<T>) => void) => {
  const { client } = useMqttStore()
  useEffect(() => {
    if (client) {
      client.on('message', (key, message, packet) => {
        const userProperties = packet.properties
          ?.userProperties as Message<T>['userProperties']
        const payload = JSON.parse(message.toString())
        const [domain, locationType, locationId, topicKey, id] = key.split('/')

        callback({
          topic: key,
          meta: {
            domain,
            locationType,
            locationId: +locationId,
            topicKey,
            id: +id,
          },
          payload,
          userProperties,
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])
}

export default useMqttSubcribtion
