import {
  Alert,
  Button,
  Text,
  createStandaloneToast,
  UseToastOptions,
} from '@chakra-ui/react'

const toast = createStandaloneToast()
const toastId = 'toast-webview'

type ToastWebviewType = {
  message: string
  type?: 'info' | 'error'
  onActionClick?: () => void
}

const ToastWebview = ({ message, type, onActionClick }: ToastWebviewType) => (
  <Alert
    sx={{
      borderRadius: '8px',
      backgroundColor: type === 'error' ? '#bb3636' : '#212121',
      justifyContent: 'space-between',
      mb: '12px',
      p: '8px 12px',
    }}
  >
    <Text fontWeight={600} fontSize="12px" lineHeight="20px" color="#ffffff">
      {message}
    </Text>

    <Button
      size="sm"
      variant="ghost"
      color={type === 'error' ? '#ffffff' : '#246EE5'}
      _hover={{
        backgroundColor: type === 'error' ? '#cd4f4f' : '#2f2e2e',
      }}
      _active={{
        backgroundColor: type === 'error' ? '#cd4f4f' : '#2f2e2e',
      }}
      onClick={() => {
        if (onActionClick) {
          onActionClick()
        }
        toast.closeAll()
      }}
    >
      <Text fontWeight={700} fontSize="14px" lineHeight="20px">
        OK
      </Text>
    </Button>
  </Alert>
)

export default ToastWebview

const DEFAULT_TOAST_OPTIONS: UseToastOptions = {
  position: 'bottom',
  isClosable: true,
  duration: 120000,
}

export const toastWebview = (
  arg: UseToastOptions & {
    message: string
    type?: 'info' | 'error'
    onActionClick?: () => void
  } = {
    message: '',
  }
) => {
  const { message, type, onActionClick, ...toastOptions } = arg
  const opts = {
    id: toastId,
    ...DEFAULT_TOAST_OPTIONS,
    ...toastOptions,
    render: () => (
      <ToastWebview
        message={message}
        type={type}
        onActionClick={onActionClick}
      />
    ),
  }

  if (!toast.isActive(toastId)) {
    toast(opts)
  } else {
    toast.update(toastId, opts)
  }
}

export const toastWebviewResponse = (
  resError?: ErrorResponse,
  defaultMessage?: string,
  toastOptions?: UseToastOptions
) => {
  const errorResponse = resError?.response

  if (errorResponse) {
    if (errorResponse?.data?.error?.message) {
      toastWebview({
        ...toastOptions,
        message: errorResponse.data.error.message,
      })
    } else {
      toastWebview({
        ...toastOptions,
        message: defaultMessage || '',
      })
    }
  }
}
