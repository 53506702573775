import L from 'leaflet'
import { markerBuyerIcon, LoadingMarker } from 'components/TrackingMap'
import useMqttSubcribtion from 'hooks/useMqttSubcribtion'

import { Marker, useMap } from 'react-leaflet'
import { useLiveTrackingOrderStore } from 'store/liveTrackingOrder'
import DriverTrackingMarkerRouteEngine from 'components/TrackingMap/DriverTrackingMarker/DriverTrackingMarkerRouteEngine'
import { useActiveArea } from 'components/TrackingMap/ActiveArea/useActiveArea'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import { ToastWebview } from 'components/ToastWebview'
import useWebViewSession from 'components/WebViewSession/useWebViewSession'

const idToast = 'toast-driver'

const Content = () => {
  const map = useMap()
  const {
    isLoading,
    isError,
    setIsLoading,
    driverPosition,
    order,
    setDriverPosition,
    setIsError,
    fetchPostGetRoute,
    driverRoute,
    setRoute,
  } = useLiveTrackingOrderStore()
  const { onSetActiveAreaWebview } = useActiveArea()
  const toast = useToast()
  const { webviewSessionProps } = useWebViewSession()

  const handleGetRoute = async (
    driverPos: [number, number],
    orderPos?: [number, number]
  ) => {
    if (orderPos) {
      const driverLocation = {
        lat: driverPos[0],
        lon: driverPos[1],
      }

      const customerLocation = {
        lat: orderPos[0],
        lon: orderPos[1],
      }

      const routes = await fetchPostGetRoute({
        locations: [driverLocation, customerLocation],
        units: 'km',
        costing: 'motorcycle',
      })

      setRoute(routes)
      return routes
    }

    return Promise<[]>
  }

  const handleRerouting = async (driverPrevPosition: [number, number]) => {
    if (order) {
      const orderPos: [number, number] = [order.lat, order.lon]
      const response = await handleGetRoute(driverPrevPosition, orderPos)
      return response
    }

    return Promise<[]>
  }

  const handleError = () => {
    if (!isError) {
      setIsError(true)
      if (!toast.isActive(idToast)) {
        toast({
          id: idToast,
          duration: 9000000,
          isClosable: true,
          render: () => (
            <ToastWebview
              message="Untuk sementara lokasi tidak dapat diperbaharui. Coba lagi nanti, ya."
              onActionClick={() => toast.closeAll()}
            />
          ),
        })
      }
    }
  }

  useMqttSubcribtion<{
    driver_status: string
    lat: number
    lon: number
    timestamp: number
  }>(({ meta, payload, userProperties }) => {
    const { id } = meta
    const { driverName } = userProperties
    const data = {
      ...payload,
      driver_id: id,
      driver_name: driverName,
    }

    setDriverPosition(data, (driverHasAdded, route, orderPosition) => {
      const driverPos: [number, number] = [+payload.lat, +payload.lon]

      if (!route.length && driverPos && orderPosition) {
        handleGetRoute(driverPos, orderPosition)
      }

      if (map && !driverHasAdded && orderPosition) {
        const orderPos: [number, number] = [
          +orderPosition[0],
          +orderPosition[1],
        ]
        const bounds = new L.LatLngBounds([orderPos, driverPos])
        try {
          map.fitBounds(bounds, { padding: [50, 50] })
        } catch {
          //
        }
      }
    })
    setIsLoading(false)
  })

  useEffect(() => {
    if (webviewSessionProps.isWebview) {
      onSetActiveAreaWebview()
    }
  }, [onSetActiveAreaWebview, webviewSessionProps.isWebview])

  return (
    order && (
      <>
        <LoadingMarker
          isLoading={isLoading}
          position={[order.lat, order.lon]}
        />
        <Marker icon={markerBuyerIcon} position={[order.lat, order.lon]} />
        {driverPosition && (
          <DriverTrackingMarkerRouteEngine
            isFromWebview
            driverId={driverPosition.driver_id}
            driverName={driverPosition.driver_name}
            driverPosition={[driverPosition.lat, driverPosition.lon]}
            focusOnDriverAndDestination
            destinationPosition={[order.lat, order.lon]}
            driverStatus="on delivery"
            duration={4500}
            latestUpdate={driverPosition.timestamp}
            isError={isError}
            onError={() => handleError()}
            showPolyline
            route={driverRoute}
            reRouting={handleRerouting as never}
          />
        )}
      </>
    )
  )
}

export default Content
