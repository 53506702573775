import { Box, Image, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Popup } from 'react-leaflet'
import warningImage from 'assets/images/png/warning.png'
import { useState } from 'react'
import { dateFormat } from 'utils/date'
import PieceTop from 'assets/images/png/pieceTop.png'

const Tooltip = styled.div`
  position: absolute;
  bottom: -100%;
  right: 118%;
  transform: translateX(8px);
  background-color: #212121;
  color: #ffffff;
  border-radius: 8px;
  padding: 12px;
  width: 271px;
  white-space: normal;
  display: flex;
  align-items: center;
  text-align: left;
`

const Triangle = styled.div`
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  border: 8px solid transparent;
  border-left-color: #212121;
`

const DriverPopupStyled = styled(Popup)`
  & .leaflet-popup-content-wrapper {
    background-color: transparent;
    box-shadow: none;
  }

  & .leaflet-popup-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;

    p {
      margin: 0;
    }
  }

  & .leaflet-popup-close-button {
    display: none;
  }

  & .leaflet-popup-tip-container {
    bottom: -55px;
    display: none;
  }

  & .leaflet-popup-tip {
    margin: 14px auto -10px;
    transform: rotate(-135deg);
    border-radius: 4px;
  }

  & .popup-driver-container {
    position: absolute;
    bottom: -80px;
    display: flex;
    align-items: center;
  }

  & .popup-driver-content {
    position: relative;
    width: fit-content;
    text-align: center;
    background-color: white;
    border-radius: 24px;
    padding: 4px 16px;
    white-space: nowrap;
    font-weight: 700;
    box-shadow: 0px 2px 12px 0px #14141452;
  }

  & .popup-driver-error {
    width: 24px;
    margin-left: 2px;
  }
`

type DriverPopupPropsType = {
  driverName?: string
  isError?: boolean
  latestUpdate?: number
  zoom: number
}

export const DriverPopup = ({
  driverName,
  isError = false,
  latestUpdate,
  zoom,
}: DriverPopupPropsType) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <DriverPopupStyled autoClose={false} closeOnClick={false} autoPan={false}>
      <Box
        className="popup-driver-container"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        cursor={isError ? 'pointer' : 'default'}
        visibility={zoom >= 15 ? 'visible' : 'hidden'}
      >
        <Box
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Box className="popup-driver-content" zIndex={3}>
            {isHovered && isError && (
              <Tooltip>
                Ada gangguan, posisi driver belum terupdate. Terakhir update:{' '}
                {latestUpdate
                  ? dateFormat({
                      date: latestUpdate,
                      format: 'DD MMM YYYY HH:mm:ss',
                    })
                  : '-'}
                <Triangle />
              </Tooltip>
            )}
            {driverName && (
              <Text fontSize="12px" fontWeight="bold" margin={0} zIndex={2}>
                {driverName}
              </Text>
            )}
          </Box>
          <Image
            position="absolute"
            width="auto"
            height={20}
            top="-41px"
            src={PieceTop}
            zIndex={3}
          />
        </Box>
        {isError && (
          <Image
            className="popup-driver-error"
            src={warningImage}
            alt="Notif Error Driver"
            visibility={zoom >= 15 ? 'visible' : 'hidden'}
          />
        )}
      </Box>
    </DriverPopupStyled>
  )
}

const DestinationPopupStyled = styled(Popup)`
  width: 234px;
  padding-bottom: 16px;

  & .leaflet-popup-close-button {
    display: none;
  }

  & .leaflet-popup-tip-container {
    display: none;
  }

  & .leaflet-popup-content {
    margin: 0px;
  }
`

type DestinationPopupProps = {
  customerName: string
  customerAddress: string
}

export const DestinationPopup = ({
  customerName,
  customerAddress,
}: DestinationPopupProps) => (
  <DestinationPopupStyled>
    <Box
      display="flex"
      flexDirection="column"
      gap="4px"
      p="8px 12px"
      borderBottom="1px solid #E9E9E9"
    >
      <Text
        fontSize="12px"
        fontWeight={400}
        lineHeight="16px"
        color="#000000"
        style={{ margin: '0' }}
      >
        Tujuan
      </Text>
      <Text
        fontSize="14px"
        fontWeight={700}
        lineHeight="20px"
        color="#000000"
        letterSpacing="0.014px"
        style={{ margin: '0 ' }}
      >
        {customerName}
      </Text>
    </Box>
    <Box display="flex" flexDirection="column" gap="4px" p="12px">
      <Text
        fontSize="12px"
        fontWeight={600}
        lineHeight="16px"
        color="#212121"
        letterSpacing="0.036px"
        style={{ margin: '0' }}
      >
        Detail Alamat
      </Text>
      <Text
        fontSize="10px"
        fontWeight={400}
        lineHeight="12px"
        color="#646464"
        style={{ margin: '0' }}
      >
        {customerAddress}
      </Text>
    </Box>
  </DestinationPopupStyled>
)
