import create from 'zustand'
import { withImmer, logger } from 'utils/store'
import {
  createLiveTrackingDriverSlice,
  type LiveTrackingDriverSlice,
} from './liveTrackingDriverSlice'

const useLiveTrackingDriverStore = create<LiveTrackingDriverSlice>(
  logger(withImmer(createLiveTrackingDriverSlice), 'MapStore')
)

export { useLiveTrackingDriverStore }
export type { LiveTrackingDriverSlice }
