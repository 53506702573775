import produce from 'immer'
import type { Draft } from 'immer'
import type { State, StateCreator } from 'zustand'

const isLogStore = process.env.NODE_ENV === 'development'

const withImmer =
  <T extends State>(
    config: StateCreator<T, (fn: (draft: Draft<T>) => void) => void>
  ): StateCreator<T> =>
  (set, get, api) =>
    config((fn) => set(produce<T>(fn)), get, api)

const logger =
  <S extends State>(config: StateCreator<S>, name: string): StateCreator<S> =>
  (
    set,
    get,
    api // eslint-disable-line
  ) =>
    config(
      (args) => {
        set(args)
        if (isLogStore) {
          console.log(name) // eslint-disable-line
          console.log('  new state', get()) // eslint-disable-line
        }
      },
      get,
      api
    )

export { withImmer, logger }
