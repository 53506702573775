import { useState } from 'react'
import { motion, useMotionValue } from 'framer-motion'
import {
  Box,
  useTheme,
  Text,
  Image,
  IconButton,
  Skeleton,
  SkeletonText,
  Button,
} from '@chakra-ui/react'
import IconDriver from 'assets/images/png/driver_avatar.png'
import IconCall from 'assets/images/png/ic_call.png'
import IconMessage from 'assets/images/png/ic_message.png'
import IconNote from 'assets/images/png/ic_add_note.png'
import { webviewPostMessage } from 'utils/postMessage'
import { ActionPostMessageNativeAppEnum } from 'constants/postMessage'
import { useLiveTrackingOrderStore } from 'store/liveTrackingOrder'
import { useParams } from 'react-router-dom'
import { useMqttStore } from 'store/mqtt'

const InformationDrawer = () => {
  const theme = useTheme()
  const { orderId } = useParams<{ hubId: string; orderId: string }>()

  const { connectStatus, setConnectStatus } = useMqttStore()
  const [isOpen, setIsOpen] = useState(false)
  const {
    order,
    isLoadingInformationDetail,
    isErrorInformationDetail,
    fetchGetOrderDetail,
  } = useLiveTrackingOrderStore()

  const snapPoints = [100, 170]

  const y = useMotionValue(0)

  const handleDragEnd = (
    _: MouseEvent | TouchEvent | PointerEvent,
    info: { offset: { y: number }; velocity: { y: number } }
  ) => {
    const offsetY = info.offset.y
    const velocityY = info.velocity.y
    const shouldOpen =
      offsetY < -(snapPoints[1] - snapPoints[0]) / 2 || velocityY < -500
    setIsOpen(shouldOpen)
    y.set(shouldOpen ? -snapPoints[1] : 0)
  }

  const handleRefetch = () => {
    const isConnectingOrConnected =
      connectStatus === 'Connecting' || connectStatus === 'Connected'

    if (!isConnectingOrConnected) {
      setConnectStatus('Reconnecting')
    }
    if (orderId) {
      fetchGetOrderDetail(+orderId)
    }
  }

  return (
    <motion.div
      initial={{ y: 0 }}
      animate={{ y: isOpen ? -snapPoints[1] : 0 }}
      exit={{ y: 0 }}
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: 205,
        boxShadow: theme.shadows.lg,
      }}
      drag={!isErrorInformationDetail && 'y'}
      dragConstraints={{ top: -snapPoints[1], bottom: 0 }}
      dragElastic={0.2}
      onDragEnd={handleDragEnd}
    >
      <Box
        bg="white"
        borderTopLeftRadius="16px"
        borderTopRightRadius="16px"
        pb={32}
      >
        {!isErrorInformationDetail ? (
          <Box width="100%">
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box h="4px" bg="#E9E9E9" borderRadius="md" w="36px" my="16px" />
            </Box>
            <Box display="flex" flexDirection="column" px={4}>
              <Box
                display="flex"
                gap="12px"
                justifyContent="space-between"
                alignItems="center"
                w="100%"
              >
                <Text fontWeight={800} fontSize="18px" lineHeight="26px">
                  Estimasi Tiba
                </Text>
                {!isLoadingInformationDetail || order ? (
                  <Box bg="#E9F1FC" p="4px 8px" borderRadius="4px">
                    <Text
                      fontWeight={700}
                      fontSize="12px"
                      lineHeight="16px"
                      color="#246EE5"
                    >
                      {order?.arrival_estimation}
                    </Text>
                  </Box>
                ) : (
                  <Skeleton height="14px" width="125px" />
                )}
              </Box>
              <Box
                py="16px"
                display="flex"
                justifyContent="space-between"
                gap="32px"
              >
                <Box display="flex" gap="8px" alignItems="center">
                  <Image w="48px" h="48px" src={IconDriver} />
                  <Box display="flex" gap="2px" flexDirection="column">
                    {!isLoadingInformationDetail || order ? (
                      <Text fontWeight={700} fontSize="14px" lineHeight="20px">
                        {order?.driver.name}
                      </Text>
                    ) : (
                      <Skeleton height="12px" width="120px" mb="4px" />
                    )}

                    {!isLoadingInformationDetail || order ? (
                      <Text
                        fontWeight={400}
                        fontSize="12px"
                        lineHeight="16px"
                        color="#646464"
                      >
                        {order?.driver.plate_number}
                      </Text>
                    ) : (
                      <Skeleton height="10px" width="80px" />
                    )}
                  </Box>
                </Box>
                <Box display="flex" gap="12px" mt="4px">
                  <IconButton
                    variant="outline"
                    aria-label="Message"
                    isRound
                    onClick={() =>
                      webviewPostMessage({
                        action:
                          ActionPostMessageNativeAppEnum.WEBVIEW_OPEN_CHAT_PAGE,
                      })
                    }
                    icon={<Image src={IconMessage} w="auto" h="17px" />}
                  />
                  <IconButton
                    variant="outline"
                    aria-label="Call"
                    isRound
                    onClick={() =>
                      webviewPostMessage({
                        action:
                          ActionPostMessageNativeAppEnum.WEBVIEW_INITIATE_CALL,
                      })
                    }
                    icon={<Image src={IconCall} w="auto" h="17px" />}
                  />
                </Box>
              </Box>
            </Box>
            <Box h="8px" w="100%" bg="#E9E9E9" />
            <Box display="flex" alignItems="flex-start" p={4}>
              <Box display="flex" gap="12px" flexDirection="column" w="100%">
                <Text fontWeight={800} fontSize="18px" lineHeight="26px">
                  Lokasi Tujuan
                </Text>
                <Box display="flex" flexDirection="column" gap="4px">
                  {!isLoadingInformationDetail || order ? (
                    <Text
                      fontWeight={400}
                      fontSize="12px"
                      lineHeight="18px"
                      color="#646464"
                      noOfLines={3}
                    >
                      {order?.address_detail}
                    </Text>
                  ) : (
                    <SkeletonText
                      noOfLines={4}
                      spacing="2"
                      skeletonHeight="2"
                    />
                  )}
                </Box>
                <Box display="flex" gap="8px" alignItems="center">
                  <Image w="auto" h="20px" src={IconNote} />
                  {!isLoadingInformationDetail || order ? (
                    <Text
                      fontWeight={400}
                      fontSize="14px"
                      lineHeight="22px"
                      color="#646464"
                      noOfLines={2}
                    >
                      {order?.address_notes || '-'}
                    </Text>
                  ) : (
                    <Skeleton height="10px" width="50%" />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box width="100%" p="16px">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              mb="24px"
            >
              <Text fontWeight={800} fontSize="20px" lineHeight="28px" mb="6px">
                Refresh Detil Pesanan
              </Text>
              <Text
                fontWeight={600}
                fontSize="14px"
                lineHeight="20px"
                color="#646464"
                textAlign="center"
              >
                Refresh untuk memunculkan detil informasi driver dan alamat
              </Text>
            </Box>
            <Box pt="16px" pb="200px">
              <Button
                isLoading={isLoadingInformationDetail}
                width="100%"
                variant="solid"
                colorScheme="blue"
                bg="#246EE5"
                onClick={handleRefetch}
              >
                Refresh
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </motion.div>
  )
}

export default InformationDrawer
