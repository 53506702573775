import { useEffect, useMemo } from 'react'
import { useMqttStore } from 'store/mqtt'

import TrackingMap from 'components/TrackingMap'

import { useLiveTrackingDriverStore } from 'store/liveTrackingDriver'
import useReceiveMessage from 'hooks/useReceiveMessage'
import { ActionPostMessageEnum } from 'constants/postMessage'
import FlyMapTo from 'components/TrackingMap/FlyMapTo'
import { useFeatureFlagStore } from 'store/featureFlag'
import { RestrictedWebview } from 'components/WebViewSession'
import { getTokenCookies } from 'utils/cookie'
import Content, { placeholderPosition } from './Content'
import { LatLngPositionType, ReceiveMessageUserData } from './type'

const LiveTrackingDriver = () => {
  const {
    isAuthorized: isMqttAuthorized,
    connectStatus,
    mqttSubcribe,
  } = useMqttStore()
  const { userData, setUserData } = useLiveTrackingDriverStore()
  const { getFeatureFlagRoutingEngine } = useFeatureFlagStore()
  const token = getTokenCookies()

  useReceiveMessage<ReceiveMessageUserData>(
    ActionPostMessageEnum.SET_USER_DATA,
    (data) => {
      const payload = {
        userId: data.payload.userId,
        username: `admin_${data.payload.userId}`,
        auth: data.payload.auth,
        location: data.payload.location,
      }
      setUserData(payload)

      getFeatureFlagRoutingEngine({
        key: 'driver_routing_engine',
        auth: payload.auth,
        locationId: payload.location.id,
      })
    }
  )

  useEffect(() => {
    if (connectStatus === 'Connected' && userData) {
      const { username } = userData
      mqttSubcribe({
        username,
        topic: `location/hub/${userData.location.id}/driver/+`,
        qos: 0,
      })
      mqttSubcribe({
        username,
        topic: `location/hub/${userData.location.id}/order/+`,
        qos: 0,
      })
    }
  }, [connectStatus, mqttSubcribe, userData])

  const location = useMemo(
    () =>
      userData
        ? ([
            userData.location.latitude,
            userData.location.longitude,
          ] as LatLngPositionType)
        : placeholderPosition,
    [userData]
  )

  if (!token || !isMqttAuthorized) {
    return <RestrictedWebview />
  }

  return (
    <TrackingMap
      className="live-tracking-driver"
      mqttUsername={userData?.username}
      mqttPassword={userData?.auth}
      hubId={userData?.location.id}
      center={location}
      zoom={13}
    >
      <FlyMapTo position={location} zoom={16} />
      <Content />
    </TrackingMap>
  )
}

export default LiveTrackingDriver
