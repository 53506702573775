import { Component } from 'react'
import ErrorImage from 'assets/images/png/something_went_wrong.png'
import { Box, Button, Image, Text } from '@chakra-ui/react'
import { parsedStackFrame, log } from '@astronautsid/wpe-utils'

interface State {
  hasError: boolean
  error?: Error
}

interface Props {
  children: JSX.Element
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(error: Error) {
    this.handleLogs(error)
  }

  /* eslint-disable class-methods-use-this */
  async handleLogs(error: Error) {
    const parsedErrorStack = await parsedStackFrame(error)
    log.error(`${error.name}: ${error.message}`, {
      customInfo: {
        origin: 'error-boundary',
      },
      error: {
        stack: parsedErrorStack,
      },
    })
  }

  render() {
    const { hasError, error } = this.state
    const { children } = this.props

    if (hasError && error) {
      // You can render any custom fallback UI
      return (
        <Box
          w="100%"
          h="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Image src={ErrorImage} w="300px" mb="24px" />
          <Box textAlign="center" px="16px" mb="16px">
            <Text fontWeight={800} fontSize="20px" lineHeight="28px">
              Lagi ada masalah server
            </Text>
            <Text
              fontWeight={600}
              fontSize="14px"
              lineHeight="20px"
              color="#646464"
            >
              Astro perbaiki secepat mungkin, ya. Kamu bisa langsung refresh
              atau coba lagi nanti.
            </Text>
          </Box>
          <Button
            w="240px"
            variant="solid"
            colorScheme="blue"
            bg="#246EE5"
            onClick={() => {
              window.location.reload()
            }}
          >
            <Text
              fontWeight={700}
              fontSize="16px"
              lineHeight="22px"
              color="#ffffff"
            >
              Refresh
            </Text>
          </Button>
        </Box>
      )
    }

    return children
  }
}

export default ErrorBoundary
