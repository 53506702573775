import { log } from '@astronautsid/wpe-utils'
import { AxiosError } from 'axios'

const { REACT_APP_DATADOG_LOG_HTTP_CODE } = process.env

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getParsedErrorResponse = (errorResponse: Record<string, any>) => {
  const message =
    errorResponse?.data?.error?.message ||
    errorResponse?.data?.error?.msg ||
    errorResponse?.data?.message ||
    errorResponse?.data?.error_message

  return message || `Failed with status code ${errorResponse.status}`
}

export const axiosErrorLoggerInterceptor = async (err: AxiosError) => {
  const errorStatusCodes = REACT_APP_DATADOG_LOG_HTTP_CODE
    ? REACT_APP_DATADOG_LOG_HTTP_CODE.split(',')
    : []

  const allowLogging =
    err.response &&
    !!errorStatusCodes.length &&
    errorStatusCodes.includes(String(err.response.status))

  if (err.isAxiosError && allowLogging) {
    const httpMethod = err.config?.method
    const httpUrl = err.config?.url
    const httpBody = err.config?.data

    if (err.response) {
      const message = getParsedErrorResponse(err.response)

      const bodyLogMessage = `${message}. ${httpMethod} ${httpUrl}`

      log.error(bodyLogMessage, {
        customInfo: {
          origin: 'network-interceptor',
          data: {
            method: httpMethod && httpMethod.toLocaleUpperCase(),
            url: httpUrl,
            body: httpBody,
            statusCode: err.response.status,
          },
        },
      })
    }
  }
}
