/* eslint-disable no-console */
import { v4 as uuidv4 } from 'uuid'
import { Wrapper } from '@googlemaps/react-wrapper'
import 'leaflet/dist/leaflet.css'
import { IClientOptions } from 'mqtt'
import { memo, useEffect } from 'react'
import { MapContainer, MapContainerProps } from 'react-leaflet'
import { useMqttStore } from 'store/mqtt'
import { toastWebview } from 'components/ToastWebview'
import { log } from '@astronautsid/wpe-utils'
import { getNetworkStatus } from 'utils/network'
import TileLayer from './TileLayer'

export type TrackingMapType = {
  children: React.ReactNode
  mqttUsername?: string
  mqttPassword?: string
  hubId?: string | number
  orderId?: string | number
} & MapContainerProps

const mqttOptions: IClientOptions = {
  keepalive: 120,
  protocolId: 'MQTT',
  protocolVersion: 5,
  clean: true,
  reconnectPeriod: 3000,
  connectTimeout: 30 * 1000,
}

const TrackingMap = ({
  children,
  mqttUsername,
  mqttPassword,
  zoom = 19,
  dragging = true,
  hubId,
  orderId,
  ...props
}: TrackingMapType) => {
  const {
    client,
    connectStatus,
    mqttConnect,
    mqttDisconnect,
    setConnectStatus,
    setMqttAuthorized,
  } = useMqttStore()

  useEffect(() => {
    const host = process.env.REACT_APP_MQTT_URL || ''
    const connectToMqtt = () => {
      const isConnectingOrConnected =
        connectStatus === 'Connecting' || connectStatus === 'Connected'

      if (!isConnectingOrConnected && mqttUsername && mqttPassword) {
        mqttConnect(host, {
          clientId: uuidv4(),
          username: mqttUsername,
          password: mqttPassword,
          ...mqttOptions,
        })
      }
    }

    connectToMqtt()
  }, [connectStatus, mqttConnect, mqttPassword, mqttUsername])

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      mqttDisconnect()
    })

    return () => {
      window.removeEventListener('beforeunload', mqttDisconnect)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (client) {
      client.on('connect', () => {
        setConnectStatus('Connected')
        console.log('Connection Successful')
      })

      client.on('error', (err) => {
        toastWebview({
          message: `Connection Error: ${err}`,
        })
        if (err?.toString().includes('Not authorized')) {
          setMqttAuthorized(false)
        }

        log.error(err?.toString(), {
          customInfo: {
            origin: 'mqtt',
            data: {
              username: mqttUsername,
              hubId,
              orderId,
            },
            networkStatus: getNetworkStatus(),
          },
        })
      })

      client.on('reconnect', () => {
        console.error('Reconnection...')
        setConnectStatus('Reconnecting')
      })

      client.on('close', () => {
        console.log('MQTT Client close')
        mqttDisconnect()
      })

      client.on('offline', () => {
        console.log('MQTT Client offline')
        mqttDisconnect()
      })
    }

    return () => {
      if (client) {
        client.removeAllListeners()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])

  return (
    <Wrapper apiKey={process.env.REACT_APP_MAPS_KEY || ''}>
      <MapContainer
        id="mapId"
        zoom={zoom}
        dragging={dragging}
        style={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
        }}
        {...props}
      >
        <TileLayer />
        {children}
      </MapContainer>
    </Wrapper>
  )
}

export default memo(TrackingMap)
