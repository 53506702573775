import './trackingDriver.css'
import L from 'leaflet'
import { Marker, useMap } from 'react-leaflet'
import { useMemo } from 'react'

import {
  DriverTrackingMarker,
  originActiveIcon,
  originNonActiveIcon,
  hubIcon,
  LoadingMarker,
  MarkerCustomerOrder,
} from 'components/TrackingMap'

import { useLiveTrackingDriverStore } from 'store/liveTrackingDriver'
import useReceiveMessage from 'hooks/useReceiveMessage'
import { ActionPostMessageEnum } from 'constants/postMessage'
import useMqttSubcribtion from 'hooks/useMqttSubcribtion'
// import { useActiveArea } from 'components/TrackingMap/ActiveArea/useActiveArea'

import {
  checkIsDriverBackToHub,
  checkIsDriverCompleted,
  checkIsDriverInTransit,
  checkIsDriverOnDelivery,
  checkIsDriverAvailable,
  checkIsDriverOnDeliveryOnly,
} from 'components/TrackingMap/utils'
import { useFeatureFlagStore } from 'store/featureFlag'
import type {
  DriverType,
  DriverSubcribtionsType,
  ReceiveMessageShowAllDriverType,
  ReceiveMessageFilterStatusDriverType,
  DriverOnlineType,
  LatLngPositionType,
} from './type'

/**
 * @default Monas Lat Long
 */
export const placeholderPosition: LatLngPositionType = [-6.175392, 106.827153]

const Content = () => {
  const map = useMap()

  const {
    isLoading,
    error,
    userData,
    filter,
    driverSelected,
    driverRoute,
    driverPosition,
    listDriverActive,
    setIsLoading,
    setFilter,
    setDriverSelected,
    setDriverPosition,
    setUpdateDriver,
    setError,
    postGetRoute,
    setRoute,
  } = useLiveTrackingDriverStore()
  const { features } = useFeatureFlagStore()

  // const { onSetActiveArea, onSetNonActiveArea } = useActiveArea()

  const listDriver = useMemo(() => {
    const drivers = Object.values(driverPosition)

    if (filter !== 'SHOW_ALL_DRIVER') {
      return drivers.filter((item) => {
        if (filter === 'FILTER_ON_DELIVERY') {
          return !!checkIsDriverOnDelivery(item.driver_status)
        }
        if (filter === 'FILTER_BACK_TO_HUB') {
          return !!checkIsDriverBackToHub(item.driver_status)
        }

        return !!checkIsDriverAvailable(item.driver_status)
      })
    }

    return drivers
  }, [driverPosition, filter])

  const driverOnline = useMemo(() => {
    if (
      userData &&
      driverSelected &&
      driverPosition[driverSelected.driver_id]
    ) {
      const driver = driverPosition[driverSelected.driver_id]
      const hubLocation: LatLngPositionType = [
        userData.location.latitude,
        userData.location.longitude,
      ]
      const orderLocation = driverSelected.orders.find((item) =>
        checkIsDriverOnDelivery(item.order_status)
      )

      const orderPoolLocation =
        driverSelected.orders.length > 1 ? driverSelected.orders : []

      let destinationPosition
      let orderIdActive = 0

      if (orderLocation) {
        destinationPosition = [
          orderLocation.order_customer_address_position.latitude,
          orderLocation.order_customer_address_position.longitude,
        ]
        orderIdActive = orderLocation.order_id
      } else if (checkIsDriverBackToHub(driver.driver_status)) {
        destinationPosition = hubLocation
      } else if (checkIsDriverOnDelivery(driver.driver_status)) {
        const orderInTransit = driverSelected.orders.find((item) =>
          checkIsDriverInTransit(item.order_status)
        )?.order_customer_address_position

        if (orderInTransit) {
          destinationPosition = [
            orderInTransit.latitude,
            orderInTransit.longitude,
          ]
        } else if (driverSelected.orders.length) {
          const latestOrder =
            driverSelected.orders[driverSelected.orders.length - 1]
              .order_customer_address_position

          destinationPosition = [latestOrder.latitude, latestOrder.longitude]
        }
      }

      if (driver) {
        const data: DriverOnlineType = {
          driverId: driver.id,
          driverName: driver.driver_name,
          driverStatus: driver.driver_status,
          driverPosition: [+driver.lat, +driver.lon],
          latestUpdate: driver.timestamp,
          hasOrder: driverSelected.orders.length > 0,
          customerName: orderLocation?.order_customer_name || '',
          customerAddress: orderLocation?.order_customer_address || '',
          destinationPosition: destinationPosition as [number, number],
          orderIdActive,
          orderPoolLocation,
        }

        return data
      }
    }

    return null
  }, [driverPosition, driverSelected, userData])

  const setDefaultMapView = () => {
    if (userData) {
      const currentZoom = map.getZoom()
      map.flyTo([userData.location.latitude, userData.location.longitude], 15, {
        duration: currentZoom !== 15 ? 0.5 : 0,
      })
    }
  }

  const recenterFocusDriver = (payload: DriverType) => {
    if (userData && payload.driver_id) {
      const driverPos = driverPosition[payload.driver_id]
      const combinePosition: LatLngPositionType[] = []

      if (driverPos) {
        combinePosition.push([driverPos.lat, driverPos.lon])

        if (checkIsDriverOnDelivery(payload.driver_status)) {
          payload.orders.forEach((item) => {
            combinePosition.push([
              item.order_customer_address_position.latitude,
              item.order_customer_address_position.longitude,
            ])
          })
        }

        if (checkIsDriverBackToHub(payload.driver_status)) {
          combinePosition.push([
            userData.location.latitude,
            userData.location.longitude,
          ])
        }
      }

      if (combinePosition.length) {
        if (combinePosition.length === 1) {
          map.flyTo(combinePosition[0], 17, {
            duration: 0.5,
          })
        } else {
          const bounds = new L.LatLngBounds(combinePosition)
          map.fitBounds(bounds, { padding: [50, 50], animate: false })
        }
      } else {
        // onSetNonActiveArea()
        setDefaultMapView()
      }
    }
  }

  const recenterAllDriver = () => {
    // onSetNonActiveArea()

    const allDriverPosition: LatLngPositionType[] = []

    Object.values(driverPosition).forEach((data) => {
      const loc = [data.lat, data.lon] as LatLngPositionType
      allDriverPosition.push(loc)
    })

    if (allDriverPosition.length) {
      const bounds = new L.LatLngBounds(allDriverPosition)
      map.fitBounds(bounds, { padding: [50, 50], maxZoom: 15 })
    } else {
      setDefaultMapView()
    }
  }

  const handleGetRoute = async (
    payload: DriverType,
    driverPrevPosition?: [number, number]
  ) => {
    const { orders } = payload
    const driverActive = driverPosition[payload.driver_id]

    if (driverActive) {
      const routeCombine: {
        lat: number
        lon: number
      }[] = []
      const driverLocation = {
        lat: driverPrevPosition?.[0] || driverActive.lat,
        lon: driverPrevPosition?.[1] || driverActive.lon,
      }

      if (checkIsDriverOnDeliveryOnly(driverActive.driver_status)) {
        const order = orders.find((item) =>
          checkIsDriverOnDeliveryOnly(item.order_status)
        )
        if (order) {
          const customerLocation = {
            lat: order.order_customer_address_position.latitude,
            lon: order.order_customer_address_position.longitude,
          }

          routeCombine.push(driverLocation, customerLocation)
        }
      } else if (checkIsDriverBackToHub(driverActive.driver_status)) {
        if (userData) {
          const hubLocation = {
            lat: userData.location.latitude,
            lon: userData.location.longitude,
          }

          routeCombine.push(driverLocation, hubLocation)
        }
      }

      const routePromises = routeCombine.slice(0, -1).map((_, index) =>
        postGetRoute({
          locations: [routeCombine[index], routeCombine[index + 1]],
          units: 'km',
          costing: 'motorcycle',
        })
      )

      const allRoutes = await Promise.all(routePromises)
      const combinedRoutes = allRoutes.flat()

      setRoute(combinedRoutes)
      return combinedRoutes
    }

    return Promise<[]>
  }

  const handleRerouting = async (driverPrevPosition: [number, number]) => {
    if (driverSelected) {
      const response = await handleGetRoute(driverSelected, driverPrevPosition)
      return response
    }
    return Promise<[]>
  }

  const onSelectDriver = (payload: DriverType) => {
    handleGetRoute(payload)

    setDriverSelected(payload, () => {
      recenterFocusDriver(payload)
    })
  }

  const onUnselectDriver = () => {
    setDriverSelected(null, () => {
      recenterAllDriver()
    })
  }

  const onDriverClick = (driverId: number, status: string) => {
    if (checkIsDriverOnDelivery(status) || checkIsDriverBackToHub(status)) {
      const getDriver = listDriverActive.find(
        (item) => item.driver_id === driverId
      )

      if (getDriver) {
        if (!driverSelected) {
          onSelectDriver(getDriver)
        } else {
          onUnselectDriver()
        }

        window.parent.postMessage(
          {
            action: !driverSelected
              ? ActionPostMessageEnum.SELECT_DRIVER
              : ActionPostMessageEnum.SHOW_ALL_DRIVER,
            payload: {
              driverId,
            },
          },
          process.env.REACT_APP_ADMIN_URL || ''
        )
      }
    }
  }

  useMqttSubcribtion<DriverSubcribtionsType>(
    ({ meta, payload, userProperties }) => {
      const { topicKey } = meta

      const { driverName, driverId } = userProperties || {}

      if (driverId) {
        const data = {
          ...payload,
          id: +driverId,
          driver_name: driverName,
          has_order: topicKey === 'order',
        }

        setDriverPosition(data, (driverHasAdded, allDriverPosition) => {
          if (!driverHasAdded && !driverSelected) {
            const bounds = new L.LatLngBounds(allDriverPosition)
            map.fitBounds(bounds, { padding: [50, 50], maxZoom: 15 })
          }
        })
        setIsLoading(false)
      }
    }
  )

  useReceiveMessage<DriverType[]>(
    ActionPostMessageEnum.UPDATE_DRIVER,
    ({ payload }) => {
      setUpdateDriver(payload)
    }
  )

  useReceiveMessage<DriverType>(
    ActionPostMessageEnum.SELECT_DRIVER,
    ({ payload }) => {
      if (driverSelected) {
        onUnselectDriver()
      } else {
        onSelectDriver(payload)
      }
      // onSetActiveArea()
    }
  )

  useReceiveMessage<ReceiveMessageShowAllDriverType>(
    ActionPostMessageEnum.SHOW_ALL_DRIVER,
    () => {
      onUnselectDriver()
    }
  )

  useReceiveMessage<{
    status: ReceiveMessageFilterStatusDriverType
  }>(ActionPostMessageEnum.FILTER_DRIVER, ({ payload }) => {
    setFilter(payload.status, (drivers) => {
      // onSetNonActiveArea()
      if (payload.status === 'SHOW_ALL_DRIVER') {
        setDefaultMapView()
      } else {
        let data: [number, number][] = []

        if (payload.status === 'FILTER_AVAILABLE') {
          data = drivers
            .filter((item) => checkIsDriverAvailable(item.driver_status))
            .map((item) => [item.lat, item.lon])
        }

        if (payload.status === 'FILTER_ON_DELIVERY') {
          data = drivers
            .filter((item) => checkIsDriverOnDelivery(item.driver_status))
            .map((item) => [item.lat, item.lon])
        }

        if (payload.status === 'FILTER_BACK_TO_HUB') {
          data = drivers
            .filter((item) => checkIsDriverBackToHub(item.driver_status))
            .map((item) => [item.lat, item.lon])
        }

        if (userData) {
          data.push([userData.location.latitude, userData.location.longitude])
        }

        if (data.length) {
          const bounds = new L.LatLngBounds(data)
          map.fitBounds(bounds, { padding: [50, 50], maxZoom: 15 })
        }
      }
    })
  })

  useReceiveMessage<DriverType>(ActionPostMessageEnum.RECENTER, (driverId) => {
    if (driverId && driverSelected) {
      recenterFocusDriver(driverSelected)
    } else {
      recenterAllDriver()
    }
  })

  return (
    userData && (
      <>
        <LoadingMarker
          isLoading={
            isLoading ||
            !!(driverSelected && !driverPosition[driverSelected.driver_id])
          }
          position={[userData.location.latitude, userData.location.longitude]}
        >
          <Marker
            icon={hubIcon}
            position={[userData.location.latitude, userData.location.longitude]}
          />
        </LoadingMarker>

        {driverOnline && (
          <>
            <DriverTrackingMarker
              key={driverOnline.driverId}
              driverId={driverOnline.driverId}
              driverName={driverOnline.driverName}
              driverStatus={driverOnline.driverStatus}
              driverPosition={driverOnline.driverPosition}
              latestUpdate={driverOnline.latestUpdate}
              orderIdActive={driverOnline.orderIdActive}
              showPolyline={
                checkIsDriverOnDelivery(driverOnline.driverStatus) ||
                checkIsDriverBackToHub(driverOnline.driverStatus)
              }
              route={driverRoute}
              focusOnDriverAndDestination
              destinationPosition={driverOnline.destinationPosition}
              duration={15000}
              isError={error[driverOnline.driverId]}
              onError={(driverId, isError) =>
                setError({ id: driverId, isError })
              }
              onClick={onDriverClick}
              reRouting={handleRerouting as never}
            />

            {checkIsDriverBackToHub(driverOnline.driverStatus) && (
              <Marker
                icon={hubIcon}
                position={[
                  userData.location.latitude,
                  userData.location.longitude,
                ]}
              />
            )}

            {checkIsDriverOnDelivery(driverOnline.driverStatus) && (
              <>
                {!driverOnline.orderPoolLocation.length &&
                  driverOnline.destinationPosition && (
                    <MarkerCustomerOrder
                      customerName={driverOnline.customerName}
                      customerAddress={driverOnline.customerAddress}
                      icon={originActiveIcon}
                      position={driverOnline.destinationPosition}
                      polyline={
                        !features.driver_routing_engine &&
                        driverOnline.orderPoolLocation.length
                          ? {
                              show: checkIsDriverCompleted(
                                driverOnline.orderPoolLocation[0].order_status
                              ),
                              destinationPosition: [
                                driverOnline.orderPoolLocation[0]
                                  .order_customer_address_position.latitude,
                                driverOnline.orderPoolLocation[0]
                                  .order_customer_address_position.longitude,
                              ],
                            }
                          : undefined
                      }
                    />
                  )}

                {driverOnline.orderPoolLocation.length &&
                  driverOnline.orderPoolLocation.map((item, index) => (
                    <MarkerCustomerOrder
                      key={item.order_id}
                      customerName={item.order_customer_name}
                      customerAddress={item.order_customer_address}
                      icon={
                        checkIsDriverCompleted(item.order_status)
                          ? originNonActiveIcon
                          : originActiveIcon
                      }
                      position={[
                        item.order_customer_address_position.latitude,
                        item.order_customer_address_position.longitude,
                      ]}
                      polyline={
                        !features.driver_routing_engine &&
                        index !== driverOnline.orderPoolLocation.length - 1
                          ? {
                              show: checkIsDriverInTransit(
                                driverOnline.orderPoolLocation?.[index + 1]
                                  .order_status
                              ),
                              destinationPosition: [
                                driverOnline.orderPoolLocation?.[index + 1]
                                  .order_customer_address_position.latitude,
                                driverOnline.orderPoolLocation?.[index + 1]
                                  .order_customer_address_position.longitude,
                              ],
                            }
                          : undefined
                      }
                    />
                  ))}
              </>
            )}
          </>
        )}

        {!driverSelected &&
          listDriver.map((item) => (
            <DriverTrackingMarker
              key={item.id}
              driverId={item.id}
              driverPosition={[item.lat, item.lon]}
              driverName={item.driver_name}
              driverStatus={item.driver_status}
              latestUpdate={item.timestamp}
              isError={error[item.id]}
              onError={(driverId, isError) =>
                setError({ id: driverId, isError })
              }
              onClick={onDriverClick}
            />
          ))}
      </>
    )
  )
}

export default Content
