import { useFeatureFlagStore } from 'store/featureFlag'
import DriverTrackingMarker from './DriverTrackingMarker'
import DriverTrackingMarkerRouteEngine, {
  DriverTrackingMarkerRouteEnginePropsType,
} from './DriverTrackingMarkerRouteEngine'

const DriverTracking = (props: DriverTrackingMarkerRouteEnginePropsType) => {
  const { features } = useFeatureFlagStore()

  if (features.driver_routing_engine) {
    return <DriverTrackingMarkerRouteEngine {...props} />
  }

  return <DriverTrackingMarker {...props} />
}

export default DriverTracking
