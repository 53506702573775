import axios from 'axios'
import { axiosErrorLoggerInterceptor } from 'config/apiService/errorLog'

import { queryParamsURL } from 'utils/queryParamsURL'
import {
  getNativeHeaders,
  getTokenCookies,
  removeTokenCookies,
  setTokenCookies,
} from './cookie'

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
})

apiClient.interceptors.request.use(
  (config) => {
    const { authToken } = queryParamsURL.get()
    const nativeHeaders = getNativeHeaders()
    const credentials =
      authToken || nativeHeaders?.Authorization || getTokenCookies()

    if (config.headers) {
      config.headers['Content-Type'] = 'application/json'
      config.headers.Authorization = `Bearer ${credentials}`
    }

    if (credentials) {
      setTokenCookies(credentials)
      queryParamsURL.remove(['authToken'])
    }

    return config
  },
  (error) => {
    if (error?.request?.status === 401) {
      removeTokenCookies()
      window.location.href = '/'
    }
    return Promise.reject(error)
  }
)

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    axiosErrorLoggerInterceptor(error)

    return Promise.reject(error)
  }
)

export { apiClient }
