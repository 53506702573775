export const driverStatus = {
  AVAILABLE: 'available',
  READY_FOR_DELIVERY: 'ready for delivery',
  ON_DELIVERY: 'on delivery',
  BACK_TO_HUB: 'back to hub',
  CONFIRMING_DELIVERY: 'confirming delivery',
  ARRIVED: 'arrived',
  IN_TRANSIT: 'in transit',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
}
