import { Box, Button, Image, Text } from '@chakra-ui/react'
import RestrictedImage from 'assets/images/png/restricted_page.png'

type RestrictedWebviewType = {
  goBack?: () => void
}

const RestrictedWebview = ({ goBack }: RestrictedWebviewType) => (
  <Box
    w="100%"
    h="100vh"
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
  >
    <Image src={RestrictedImage} w="300px" mb="24px" />
    <Box textAlign="center" px="16px" mb="16px">
      <Text fontWeight={800} fontSize="20px" lineHeight="28px">
        Halaman tidak bisa di akses
      </Text>
      <Text fontWeight={600} fontSize="14px" lineHeight="20px" color="#646464">
        Halaman tidak bisa di akses karena link bermasalah atau kadaluarsa
      </Text>
    </Box>
    {goBack && (
      <Button
        w="240px"
        variant="solid"
        colorScheme="blue"
        bg="#246EE5"
        onClick={goBack}
      >
        <Text
          fontWeight={700}
          fontSize="16px"
          lineHeight="22px"
          color="#ffffff"
        >
          Kembali
        </Text>
      </Button>
    )}
  </Box>
)

export default RestrictedWebview
