import { LatLngTuple } from 'leaflet'
import { Marker, MarkerProps, Polyline } from 'react-leaflet'
import { DestinationPopup } from '../Popup'

type MarkerCustomerOrderProps = MarkerProps & {
  customerName: string
  customerAddress: string
  polyline?: {
    show: boolean
    destinationPosition: [number, number] | LatLngTuple
  }
}

export const MarkerCustomerOrder = ({
  customerName,
  customerAddress,
  position,
  icon,
  polyline,
}: MarkerCustomerOrderProps) => (
  <Marker
    position={position}
    icon={icon}
    eventHandlers={{
      mouseover: (event) => event.target.openPopup(),
      mouseout: (event) => event.target.closePopup(),
    }}
  >
    <DestinationPopup
      customerName={customerName}
      customerAddress={customerAddress}
    />
    {polyline?.show && (
      <Polyline
        positions={[position, polyline.destinationPosition]}
        color="#246EE5"
      />
    )}
  </Marker>
)
