import create from 'zustand'
import { withImmer, logger } from 'utils/store'
import { createPoolingMapSlice, type PoolingMapSlice } from './poolingMapSlice'

const usePoolingMapStore = create<PoolingMapSlice>(
  logger(withImmer(createPoolingMapSlice), 'MapStore')
)

export { usePoolingMapStore }
export type { PoolingMapSlice }
