export interface QueryObj {
  [key: string]: string | number | boolean
}

const removeObjFromArray = (qs: QueryObj, query: string[]) =>
  Object.keys(qs).reduce((obj: QueryObj, key) => {
    if (!query.includes(key)) {
      obj[key] = qs[key]
    }
    return obj
  }, {})

const queryObjToString = (data: QueryObj) =>
  Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join('&')

const queryStringToObj = (url: string) =>
  Object.fromEntries(new URLSearchParams(url))

const queryParamsURL = {
  get() {
    const qs = window.location.search
    return queryStringToObj(qs)
  },
  set(cb: (q: QueryObj) => QueryObj) {
    const qs = this.get()
    const newQuery = cb(qs)
    window.history.replaceState(
      this.get(),
      '',
      `?${queryObjToString(newQuery)}`
    )
  },
  remove(query: string[]) {
    const qsObj = this.get()
    const newQueryObj = removeObjFromArray(qsObj, query)
    window.history.replaceState(
      this.get(),
      '',
      `?${queryObjToString(newQueryObj)}`
    )
  },
}

export { queryParamsURL }
