import create from 'zustand'
import { withImmer, logger } from 'utils/store'
import { createMqttSlice, type MqttSlice } from './mqttSlice'

const useMqttStore = create<MqttSlice>(
  logger(withImmer(createMqttSlice), 'MapStore')
)

export { useMqttStore }
export type { MqttSlice }
