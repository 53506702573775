import { useMap } from 'react-leaflet'
import 'leaflet-active-area'

type MapOptions = {
  setActiveArea: (
    active: string | HTMLElement,
    recenterMap?: boolean,
    animateMap?: boolean
  ) => void
}

export const useActiveArea = () => {
  const map = useMap()

  const onSetActiveArea = () => {
    ;(map as unknown as MapOptions).setActiveArea('activeArea', true, true)
  }

  const onSetActiveAreaWebview = () => {
    ;(map as unknown as MapOptions).setActiveArea(
      'activeAreaWebview',
      true,
      true
    )
  }

  const onSetNonActiveArea = () => {
    ;(map as unknown as MapOptions).setActiveArea('deactiveArea', true, true)
  }

  return {
    onSetActiveArea,
    onSetActiveAreaWebview,
    onSetNonActiveArea,
  }
}
