/* eslint-disable @typescript-eslint/no-explicit-any */
type NetworkStatus = {
  connectionType: string
  downlink: string
  rtt: string
  saveData: boolean
} | null

export const getNetworkStatus = (): NetworkStatus => {
  if ('connection' in navigator) {
    const connection =
      navigator.connection ||
      (navigator as any).mozConnection ||
      (navigator as any).webkitConnection

    return {
      connectionType: connection.effectiveType,
      downlink: `${connection.downlink} Mb/s`,
      rtt: `${connection.rtt} ms`,
      saveData: connection.saveData,
    }
  }

  return null
}
