import useWebViewSession from './useWebViewSession'

type WebviewWrapperPropsType = {
  children: React.ReactNode
}

const WebViewSession = ({ children }: WebviewWrapperPropsType) => {
  const { loading } = useWebViewSession()

  if (loading) {
    return null
  }

  return <>{children}</>
}

export default WebViewSession
