import { driverStatus } from 'constants/driverStatus'

type PositionType = [number, number]

const {
  REACT_APP_DISTANCE_THRESHOLD_REROUTE,
  REACT_APP_CLOSEST_POINT_THRESHOLD,
} = process.env

export const DISTANCE_THRESHOLD_REROUTE = REACT_APP_DISTANCE_THRESHOLD_REROUTE
  ? +REACT_APP_DISTANCE_THRESHOLD_REROUTE
  : 0
export const CLOSEST_POINT_THRESHOLD = REACT_APP_CLOSEST_POINT_THRESHOLD
  ? +REACT_APP_CLOSEST_POINT_THRESHOLD
  : 0

export const checkIsDriverOnDelivery = (status: string) =>
  status.toLowerCase() === driverStatus.READY_FOR_DELIVERY ||
  status.toLowerCase() === driverStatus.CONFIRMING_DELIVERY ||
  status.toLowerCase() === driverStatus.ON_DELIVERY ||
  status.toLowerCase() === driverStatus.ARRIVED

export const checkIsDriverAvailable = (status: string) =>
  status.toLowerCase() === driverStatus.AVAILABLE

export const checkIsDriverBackToHub = (status: string) =>
  status.toLowerCase() === driverStatus.BACK_TO_HUB

export const checkIsDriverInTransit = (status: string) =>
  status.toLowerCase() === driverStatus.IN_TRANSIT

export const checkIsDriverArrived = (status: string) =>
  status.toLowerCase() === driverStatus.ARRIVED

export const checkIsDriverCompleted = (status: string) =>
  status.toLowerCase() === driverStatus.COMPLETED

export const checkIsDriverOnDeliveryOnly = (status: string) =>
  status.toLowerCase() === driverStatus.ON_DELIVERY ||
  status.toLowerCase() === driverStatus.ARRIVED

export const checkIsDriverCancelled = (status: string) =>
  status.toLowerCase() === driverStatus.CANCELLED

export const calculateDistance = (pos1: PositionType, pos2: PositionType) => {
  if (!pos1 || !pos2) {
    return 0
  }

  try {
    const R = 6371000 // Radius of the Earth in meters
    const dLat = ((pos2[0] - pos1[0]) * Math.PI) / 180
    const dLon = ((pos2[1] - pos1[1]) * Math.PI) / 180
    const lat1 = (pos1[0] * Math.PI) / 180
    const lat2 = (pos2[0] * Math.PI) / 180

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return R * c // Distance in meters
  } catch {
    return 0
  }
}

export const findClosestNodeOnRoute = (
  route: PositionType[],
  position: PositionType
) => {
  let closestNode = route[0]
  let minDistance = calculateDistance(route[0], position)

  for (let i = 1; i < route.length; i++) {
    const distance = calculateDistance(route[i], position)
    if (distance < minDistance) {
      minDistance = distance
      closestNode = route[i]
    }
  }

  return closestNode
}

export const checkIsOnRoutePath = (
  driverPosition: PositionType,
  route: PositionType[]
) => {
  for (let i = 0; i < route.length - 1; i++) {
    const segmentStart = route[i]
    const segmentEnd = route[i + 1]

    const distanceToStart = calculateDistance(driverPosition, segmentStart)
    const distanceToEnd = calculateDistance(driverPosition, segmentEnd)

    if (
      distanceToStart <= CLOSEST_POINT_THRESHOLD ||
      distanceToEnd <= CLOSEST_POINT_THRESHOLD
    ) {
      return true
    }

    const d1 = calculateDistance(driverPosition, segmentStart)
    const d2 = calculateDistance(driverPosition, segmentEnd)
    const d3 = calculateDistance(segmentStart, segmentEnd)

    if (d1 + d2 <= d3 + CLOSEST_POINT_THRESHOLD) {
      return true
    }
  }

  return false
}

export const getRouteSegment = (
  route: PositionType[],
  start: PositionType,
  end: PositionType
): PositionType[] => {
  const coordinatesEqual = (
    coord1: PositionType,
    coord2: PositionType
  ): boolean => coord1[0] === coord2[0] && coord1[1] === coord2[1]

  let startIndex = -1
  let endIndex = -1

  for (let i = 0; i < route.length; i++) {
    if (coordinatesEqual(route[i], start)) {
      startIndex = i
    }
    if (coordinatesEqual(route[i], end)) {
      endIndex = i
    }
    if (startIndex !== -1 && endIndex !== -1) {
      break
    }
  }

  if (startIndex === -1 || endIndex === -1) {
    return []
  }

  return route.slice(startIndex, endIndex + 1)
}

export const calculateSpeed = (distance: number, elapsedTime: number) => {
  const speed = distance / elapsedTime

  return speed
}

export const calculateStepDuration = (
  segmentDistance: number,
  elapsedTime: number
) => {
  const speed = calculateSpeed(segmentDistance, elapsedTime)
  const stepDuration = segmentDistance / speed

  return stepDuration
}

export const calculateElapsedTime = (
  lastUpdate: number,
  currentTimestamp: number
) => {
  if (!lastUpdate) {
    return 0
  }

  const elapsedTime = currentTimestamp - lastUpdate

  return elapsedTime
}

export const getSegmentDuration = (
  prevPosition: PositionType,
  nextPosition: PositionType,
  latestUpdate: number
) => {
  const elapsedTime = calculateElapsedTime(latestUpdate, Date.now())
  const segmentDistance = calculateDistance(prevPosition, nextPosition)
  const stepDuration = calculateStepDuration(segmentDistance, elapsedTime)

  return { segmentDistance, stepDuration }
}

export const findSegment = (
  route: PositionType[],
  startPosition: PositionType,
  endPosition: PositionType
): PositionType[] => {
  const startIndex = route.findIndex(
    (pos) => pos[0] === startPosition[0] && pos[1] === startPosition[1]
  )
  const endIndex = route.findIndex(
    (pos) => pos[0] === endPosition[0] && pos[1] === endPosition[1]
  )

  if (startIndex === -1 || endIndex === -1) {
    return []
  }

  return route.slice(startIndex, endIndex + 1)
}

export function getPositionBeforeRouteStart(
  route: PositionType[],
  firstRoutePosition: PositionType
) {
  const firstIndex = route.findIndex(
    (point) =>
      point[0] === firstRoutePosition[0] && point[1] === firstRoutePosition[1]
  )

  if (firstIndex > 0) {
    return route[firstIndex - 1]
  }

  return firstRoutePosition
}

export const isMoreThanTwoMinutes = (timestamp: number): boolean => {
  const twoMinutesInMillis = 2 * 60 * 1000
  const currentTime = new Date().getTime()
  return currentTime - timestamp > twoMinutesInMillis
}
