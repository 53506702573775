import './trackingOrder.css'
import TrackingMap from 'components/TrackingMap'
import { useEffect, useMemo, useState } from 'react'

import { useMqttStore } from 'store/mqtt'
import { useNavigate, useParams } from 'react-router-dom'
import useWebViewSession from 'components/WebViewSession/useWebViewSession'
import { useLiveTrackingOrderStore } from 'store/liveTrackingOrder'
import FlyMapTo from 'components/TrackingMap/FlyMapTo'
import { RestrictedWebview } from 'components/WebViewSession'
import { webviewPostMessage } from 'utils/postMessage'
import { ActionPostMessageNativeAppEnum } from 'constants/postMessage'
import CompleteOrderImage from 'assets/images/png/order_completed.png'
import RestrictedImage from 'assets/images/png/restricted_page.png'

import Content from './Content'
import InformationDrawer from './InformationDrawer'
import OrderSummary from './OrderSummary'

/**
 * @default Monas Lat Long
 */
const placeholderPosition: [number, number] = [-6.175392, 106.827153]

const LiveTrackingOrder = () => {
  const navigate = useNavigate()
  const { connectStatus, mqttSubcribe, isSubcribed } = useMqttStore()
  const { hubId, orderId } = useParams<{ hubId: string; orderId: string }>()
  const { webviewSessionProps } = useWebViewSession()
  const { isCompleted, isCancelled, order, fetchGetOrderDetail } =
    useLiveTrackingOrderStore()

  const [restrictedPage, setRestrictedPage] = useState(false)

  const mqqttAuth = useMemo(() => {
    if (webviewSessionProps.nativeHeaders) {
      const { userId, Authorization } = webviewSessionProps.nativeHeaders

      return {
        username: `buyer_${userId}`,
        password: Authorization,
      }
    }

    return null
  }, [webviewSessionProps.nativeHeaders])

  useEffect(() => {
    if (
      connectStatus === 'Connected' &&
      !isSubcribed &&
      hubId &&
      orderId &&
      webviewSessionProps.nativeHeaders
    ) {
      const { userId } = webviewSessionProps.nativeHeaders
      mqttSubcribe({
        username: `buyer_${userId}`,
        topic: `location/hub/${hubId}/order/${orderId}`,
        qos: 0,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubcribed, connectStatus])

  useEffect(() => {
    let timer: NodeJS.Timer | null = null

    if (orderId && !isCompleted) {
      const fetchData = async () => {
        fetchGetOrderDetail(+orderId, {
          onError: (err) => {
            if (err?.message?.includes('401')) {
              setRestrictedPage(true)
            }
            if (timer !== null) {
              clearInterval(timer)
            }
          },
        })
      }

      if (mqqttAuth) {
        fetchData()
      }

      timer = setInterval(fetchData, 10000)
    } else if (timer !== null) {
      clearInterval(timer)
      timer = null
    }

    return () => {
      if (timer !== null) {
        clearInterval(timer)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  const location = useMemo(
    () =>
      order
        ? ([order.lat, order.lon] as [number, number])
        : placeholderPosition,
    [order]
  )

  if (!mqqttAuth || restrictedPage) {
    return (
      <RestrictedWebview
        goBack={() => {
          if (webviewSessionProps.isWebview) {
            webviewPostMessage({
              action: ActionPostMessageNativeAppEnum.WEBVIEW_NAVIGATE_BACK,
            })
          } else {
            navigate(-1)
          }
        }}
      />
    )
  }

  if (isCompleted) {
    return (
      <OrderSummary
        title="Pesanan sudah selesai"
        message="Driver sudah selesai mengantar pesanan Anda, silahkan cek pesanan Anda"
        image={CompleteOrderImage}
      />
    )
  }

  if (isCancelled) {
    return (
      <OrderSummary
        title="Pesanan anda telah dibatalkan"
        message="Halaman tidak bisa di akses"
        image={RestrictedImage}
      />
    )
  }

  return (
    <>
      <TrackingMap
        key={location[0].toString()}
        className="live-tracking-order"
        mqttUsername={mqqttAuth.username}
        mqttPassword={mqqttAuth.password}
        hubId={hubId}
        orderId={orderId}
        center={location}
        zoom={15}
      >
        <FlyMapTo position={location} zoom={16} />
        <Content />
      </TrackingMap>
      <InformationDrawer />
    </>
  )
}

export default LiveTrackingOrder
