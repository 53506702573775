import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import { Marker, useMap } from 'react-leaflet'

import DriverTrackingMarkerRouteEngine from 'components/TrackingMap/DriverTrackingMarker/DriverTrackingMarkerRouteEngine'
import { markerBuyerIcon, LoadingMarker } from 'components/TrackingMap'
import { ToastWebview } from 'components/ToastWebview'
import { useLiveTrackingCrewStore } from 'store/liveTrackingCrew'
import { ActionCustomEventEnum } from 'constants/customEvent'

const idToast = 'toast-driver'

const Content = () => {
  const map = useMap()
  const {
    isLoading,
    isError,
    setIsLoading,
    driverPosition,
    order,
    setDriverPosition,
    setIsError,
    fetchPostGetRouteInternal,
    driverRoute,
    setRoute,
  } = useLiveTrackingCrewStore()

  const toast = useToast()

  const handleGetRoute = async (
    driverPos: [number, number],
    orderPos?: [number, number]
  ) => {
    if (orderPos) {
      const driverLocation = {
        lat: driverPos[0],
        lon: driverPos[1],
      }

      const customerLocation = {
        lat: orderPos[0],
        lon: orderPos[1],
      }

      const routes = await fetchPostGetRouteInternal({
        locations: [driverLocation, customerLocation],
        units: 'km',
        costing: 'motorcycle',
      })

      setRoute(routes)
      return routes
    }

    return Promise<[]>
  }

  const handleRerouting = async (driverPrevPosition: [number, number]) => {
    if (order) {
      const orderPos: [number, number] = [order.lat, order.lon]
      const response = await handleGetRoute(driverPrevPosition, orderPos)
      return response
    }

    return Promise<[]>
  }

  const handleError = () => {
    if (!isError) {
      setIsError(true)
      if (!toast.isActive(idToast)) {
        toast({
          id: idToast,
          duration: 9000000,
          isClosable: true,
          render: () => (
            <ToastWebview
              message="Untuk sementara lokasi tidak dapat diperbaharui. Coba lagi nanti, ya."
              onActionClick={() => toast.closeAll()}
            />
          ),
        })
      }
    }
  }

  useEffect(() => {
    const handleDriverLocationUpdate = (event: Event) => {
      const customEvent = (
        event as CustomEvent<{
          driver_id: number
          driver_status: string
          driver_name: string
          latitude: number
          longitude: number
          timestamp: number
        }>
      )?.detail

      const payload = customEvent
        ? {
            driver_id: customEvent.driver_id,
            driver_name: customEvent?.driver_name || '',
            driver_status: customEvent.driver_status,
            lat: customEvent.latitude,
            lon: customEvent.longitude,
            timestamp: customEvent.timestamp,
          }
        : null

      if (payload) {
        setDriverPosition(
          payload,
          ({ driverHasAdded, route, orderPosition, isAuthorized }) => {
            const driverPos: [number, number] = [+payload.lat, +payload.lon]

            if (isAuthorized && !route.length && driverPos && orderPosition) {
              handleGetRoute(driverPos, orderPosition)
            }

            if (!driverHasAdded && orderPosition) {
              map.setView(driverPos, 19)
            }
          }
        )
        setIsLoading(false)
      }
    }

    window.addEventListener(
      ActionCustomEventEnum.DRIVER_LOCATION_UPDATE,
      handleDriverLocationUpdate
    )

    return () => {
      window.removeEventListener(
        ActionCustomEventEnum.DRIVER_LOCATION_UPDATE,
        handleDriverLocationUpdate
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    order && (
      <>
        <LoadingMarker
          isLoading={isLoading}
          position={[order.lat, order.lon]}
        />
        <Marker icon={markerBuyerIcon} position={[order.lat, order.lon]} />
        {driverPosition && (
          <DriverTrackingMarkerRouteEngine
            key={driverPosition.driver_id}
            isFromWebview
            withMoveAnimation={false}
            driverId={driverPosition.driver_id}
            driverName={driverPosition.driver_name}
            driverPosition={[driverPosition.lat, driverPosition.lon]}
            focusOnDriverAndDestination
            destinationPosition={[order.lat, order.lon]}
            driverStatus="on delivery"
            duration={2000}
            latestUpdate={driverPosition.timestamp}
            isError={isError}
            onError={() => handleError()}
            showPolyline
            route={driverRoute}
            reRouting={handleRerouting as never}
            keepAtCenter
          />
        )}
      </>
    )
  )
}

export default Content
