export const getFirstName = (fullName: string): string => {
  if (!fullName) return ''

  const parts = fullName.split(' ')
  const firstName = parts[0]

  return firstName
}

export const uppercaseFirstLetter = (str = ''): string =>
  str.toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())
