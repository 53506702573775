import { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'

import ProtectedRoute from 'components/ProtectedRoute'
import WebViewSession from 'components/WebViewSession'
import ErrorBoundary from 'components/ErrorBoundary'
import useAppInit from 'hooks/useAppInit'

import LiveTrackingDriver from 'pages/LiveTrackingDriver'
import LiveTrackingOrder from 'pages/LiveTrackingOrder'

import PoolingMap from 'pages/PoolingMap'
import LiveTrackingCrew from 'pages/LiveTrackingCrew'
import {
  isochrone,
  isochroneAll,
  isochroneValidation,
  locationOrder,
  quadrantIsochrone,
  applicationHealth,
} from 'config/routes'

const IsochronePage = lazy(() => import('pages/Isochrone'))
const IsochroneAllPage = lazy(() => import('pages/IsochroneAll'))
const QuadrantIsochronePage = lazy(() => import('pages/QuadrantIsochrone'))
const LocationOrderPage = lazy(() => import('pages/LocationOrder'))
const IsochroneValidationPage = lazy(() => import('pages/IsochroneValidation'))

const customTheme = extendTheme({
  fonts: {
    heading: `'Nunito sans', sans-serif`,
    body: `'Nunito sans', sans-serif`,
  },
})

const App = (): JSX.Element => {
  useAppInit()

  return (
    <ChakraProvider theme={customTheme}>
      <ErrorBoundary>
        <Suspense fallback="">
          <Routes>
            <Route
              element={<h4>The app is healthy</h4>}
              path={applicationHealth}
            />

            <Route
              element={
                <ProtectedRoute>
                  <IsochronePage />
                </ProtectedRoute>
              }
              path={isochrone}
            />
            <Route
              element={
                <ProtectedRoute>
                  <IsochroneAllPage />
                </ProtectedRoute>
              }
              path={isochroneAll}
            />
            <Route
              element={
                <ProtectedRoute>
                  <QuadrantIsochronePage />
                </ProtectedRoute>
              }
              path={quadrantIsochrone}
            />
            <Route
              element={
                <ProtectedRoute>
                  <LocationOrderPage />
                </ProtectedRoute>
              }
              path={locationOrder}
            />
            <Route
              element={
                <ProtectedRoute>
                  <IsochroneValidationPage />
                </ProtectedRoute>
              }
              path={isochroneValidation}
            />

            <Route
              element={<LiveTrackingDriver />}
              path="/live-tracking-driver"
            />
            <Route
              element={
                <WebViewSession>
                  <LiveTrackingOrder />
                </WebViewSession>
              }
              path="/live-tracking-order/:hubId/:orderId"
            />
            <Route
              element={
                <WebViewSession>
                  <LiveTrackingCrew />
                </WebViewSession>
              }
              path="/live-tracking-crew/:hubId/:orderId"
            />
            <Route element={<PoolingMap />} path="/pooling-map" />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </ChakraProvider>
  )
}
export default App
