import { parseCookie } from 'utils/cookie'

export type NativeHeaders = {
  userId: string
  Authorization: string
  'X-API-VERSION': string
  'X-APP-VERSION': string
  'X-DEVICE': 'ios' | 'android'
  'X-DEVICE-ID': string
  'X-SESSION-ID': string
}

export type WebviewSessionPropsType<NativeDataType = Record<string, unknown>> =
  {
    nativeHeaders: NativeHeaders | null
    initialNativeData?: NativeDataType
    isWebview: boolean
    isAuthenticated: boolean
    hasSessionCheck: boolean
  }

export const initialWebviewSessionProps: WebviewSessionPropsType = {
  nativeHeaders: null,
  initialNativeData: {},
  isWebview: false,
  isAuthenticated: false,
  hasSessionCheck: false,
}

export const getWebviewSessionProps = (
  cookieString: string,
  searchParams: Record<string, unknown> = {}
) => {
  const cookies = parseCookie(cookieString)

  const nativeHeaders: NativeHeaders | null = cookies?.nativeHeaders
    ? JSON.parse(cookies.nativeHeaders)
    : null

  const initialNativeData = cookies?.initialNativeData
    ? JSON.parse(cookies.initialNativeData)
    : null

  const mergedInitialDataWithSearchParams: Record<string, unknown> = {
    ...(initialNativeData || {}),
    ...searchParams,
  }

  return {
    nativeHeaders,
    initialNativeData: mergedInitialDataWithSearchParams,
    isWebview: !!nativeHeaders,
    isAuthenticated: !!nativeHeaders?.Authorization,
    hasSessionCheck: true,
  } as WebviewSessionPropsType
}

export const getWebviewSessionPropsFromWindowCtx = (
  nativeHeaders: NativeHeaders
) =>
  ({
    nativeHeaders: window.nativeHeaders,
    initialNativeData: {},
    isWebview: !!nativeHeaders,
    isAuthenticated: !!nativeHeaders?.Authorization,
    hasSessionCheck: true,
  } as WebviewSessionPropsType)

export const getWebviewSessionPropsDefault = () =>
  ({
    nativeHeaders: window.nativeHeaders,
    initialNativeData: {},
    isWebview: !!window.nativeHeaders,
    isAuthenticated: false,
    hasSessionCheck: true,
  } as WebviewSessionPropsType)
