type PostMessageEvent = {
  action: string
  payload?: Record<string, unknown>
}

export const webviewPostMessage = (data: PostMessageEvent) => {
  const message = JSON.stringify(data)

  // Send message to Android
  if (window?.android) {
    window.android.postMessage(message)
  }

  // Send message to iOS
  if (window?.webkit?.messageHandlers?.iOS) {
    window.webkit.messageHandlers.iOS.postMessage(message)
  }
}
