import { ReactNode } from 'react'
import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react'

const toast = createStandaloneToast()

const DEFAULT_ERROR_MESSAGE = 'Something went wrong, please try again later.'

const DEFAULT_TOAST_OPTIONS: UseToastOptions = {
  position: 'top',
  isClosable: true,
}

export const toastSuccess = (
  content: ReactNode,
  toastOptions: UseToastOptions = {}
) => {
  toast({
    ...DEFAULT_TOAST_OPTIONS,
    ...toastOptions,
    status: 'success',
    title: content,
  })
}

export const toastError = (
  content?: ReactNode,
  toastOptions: UseToastOptions = {}
) => {
  toast({
    ...DEFAULT_TOAST_OPTIONS,
    ...toastOptions,
    status: 'error',
    title: content || DEFAULT_ERROR_MESSAGE,
  })
}

export const toastInfo = (
  content?: ReactNode,
  toastOptions: UseToastOptions = {}
) => {
  toast({
    ...DEFAULT_TOAST_OPTIONS,
    ...toastOptions,
    status: 'info',
    title: content || DEFAULT_ERROR_MESSAGE,
  })
}

export const toastErrResponse = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resError: any,
  defaultMessage?: ReactNode,
  toastOptions?: UseToastOptions
) => {
  const errorResponse = resError?.response

  if (errorResponse) {
    if (errorResponse?.data?.error?.message) {
      toastError(errorResponse.data.error.message, toastOptions)
    } else {
      toastError(defaultMessage, toastOptions)
    }
  }
}
