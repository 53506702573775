import create from 'zustand'
import { withImmer, logger } from 'utils/store'
import {
  createFeatureFlagSlice,
  type FeatureFlagSlice,
} from './featureFlagSlice'

const useFeatureFlagStore = create<FeatureFlagSlice>(
  logger(withImmer(createFeatureFlagSlice), 'MapStore')
)

export { useFeatureFlagStore }
export type { createFeatureFlagSlice }
