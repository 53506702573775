import { LatLngTuple } from 'leaflet'
import { DeliveriesType } from 'pages/PoolingMap/type'

export type PoolingMapSlice = {
  isLoading: boolean
  isError: boolean
  hubLocation: LatLngTuple
  allDeliveryOrder: DeliveriesType
  mapZoom: number
  mapCenter: LatLngTuple
  hasPooling: string
  selectedOrder: DeliveriesType
  setIsLoading: (isLoading: boolean) => void
  setIsError: (isError: boolean) => void
  setHubLocation: (hubLocation: LatLngTuple) => void
  setAllDeliveryOrder: (allDeliveryOrder: DeliveriesType) => void
  setMapZoom: (mapZoom: number) => void
  setMapCenter: (mapCenter: LatLngTuple) => void
  setHasPooling: (hasPooling: string) => void
  setSelectedOrder: (selectedOrder: DeliveriesType[number]) => void
  setReset: () => void
  setRemoveOrder: (selectedOrder: DeliveriesType[number]) => void
}

export const createPoolingMapSlice: StoreSlice<PoolingMapSlice> = (
  set
  // get
) => ({
  isLoading: true,
  isError: false,
  hubLocation: [-6.175392, 106.827153],
  allDeliveryOrder: [],
  mapZoom: 14,
  mapCenter: [-6.175392, 106.827153],
  hasPooling: '0',
  selectedOrder: [],
  setIsLoading: (isLoading) => {
    set((state) => {
      state.isLoading = isLoading
    })
  },
  setIsError: (isError) => {
    set((state) => {
      state.isError = isError
    })
  },
  setHubLocation: (hubLocation) => {
    set((state) => {
      state.hubLocation = hubLocation
    })
  },
  setAllDeliveryOrder: (allDeliveryOrder) => {
    set((state) => {
      state.allDeliveryOrder = allDeliveryOrder
    })
  },
  setMapZoom: (mapZoom) => {
    set((state) => {
      state.mapZoom = mapZoom
    })
  },
  setMapCenter: (mapCenter) => {
    set((state) => {
      state.mapCenter = mapCenter
    })
  },
  setHasPooling: (hasPooling) => {
    set((state) => {
      state.hasPooling = hasPooling
    })
  },
  setSelectedOrder: (selectedOrder) => {
    set((state) => {
      const ordersToAdd =
        selectedOrder.pool_number !== '0'
          ? state.allDeliveryOrder.filter(
              (order) => order.pool_number === selectedOrder.pool_number
            )
          : [selectedOrder]

      state.selectedOrder = [...state.selectedOrder, ...ordersToAdd]
      state.hasPooling = selectedOrder.pool_number || '0'
    })
  },
  setRemoveOrder: (selectedOrder) => {
    set((state) => {
      state.selectedOrder = state.selectedOrder.filter(
        (order) => order.order_id !== selectedOrder.order_id
      )
    })
  },
  setReset: () => {
    set((state) => {
      state.selectedOrder = []
      state.hasPooling = '0'
    })
  },
})
