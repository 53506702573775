import { useEffect } from 'react'
import { Box, Spinner } from '@chakra-ui/react'

import { useAuthStore } from 'store/auth'
import { navigateToAdmin } from 'utils/navigateToAdminWeb'
import { queryParamsURL } from 'utils/queryParamsURL'
import { getTokenCookies } from 'utils/cookie'

interface ProtectedRouteProps {
  children: React.ReactNode
}

const { fetchTokenValidate } = useAuthStore.getState()
const { authToken } = queryParamsURL.get()

const ProtectedRoute = ({ children }: ProtectedRouteProps): JSX.Element => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)

  useEffect(() => {
    const cookiesToken = authToken || getTokenCookies()
    if (cookiesToken) {
      fetchTokenValidate()
    } else {
      navigateToAdmin()
    }
  }, [])

  return !isAuthenticated ? (
    <Box h="100vh" display="flex" justifyContent="center" alignItems="center">
      <Spinner size="xl" />
    </Box>
  ) : (
    <>{children}</>
  )
}

export default ProtectedRoute
