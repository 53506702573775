import create from 'zustand'
import { withImmer, logger } from 'utils/store'

import { createAuthSlice, type AuthSlice } from './authState'
import { createAuthApi, type AuthApi } from './authApi'

type MergeSlice = AuthSlice & AuthApi

const useAuthStore = create<MergeSlice>(
  logger(
    withImmer<MergeSlice>((set, get, api) => ({
      ...createAuthSlice(set, get, api),
      ...createAuthApi(set, get, api),
    })),
    'AuthStore'
  )
)

export { useAuthStore }
export type { AuthSlice, AuthApi }
