import { LatLngExpression } from 'leaflet'
import { useEffect } from 'react'
import { useMap } from 'react-leaflet'

type FlyMapToProps = {
  position: LatLngExpression | [number, number]
  zoom?: number
}

const FlyMapTo = ({ position, zoom }: FlyMapToProps) => {
  const map = useMap()

  useEffect(() => {
    map.flyTo(position, zoom, { duration: 1 })
  }, [map, position, zoom])

  return null
}

export default FlyMapTo
