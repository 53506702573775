import { useEffect, useState } from 'react'
import { useAuthStore } from 'store/auth'
import { parseCookie, setTokenCookies } from 'utils/cookie'
import {
  getWebviewSessionProps,
  getWebviewSessionPropsDefault,
  getWebviewSessionPropsFromWindowCtx,
  NativeHeaders,
} from 'utils/webviewSession'

const wait = (seconds: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000)
  })

const useWebViewSession = () => {
  const { webviewSessionProps, setWebviewSessionProps } = useAuthStore()
  const [loading, setLoading] = useState(true)

  const getWebviewSessionPropsBySource = async () => {
    // check cookie
    const cookies = parseCookie(document.cookie)
    if (cookies?.nativeHeaders) {
      return getWebviewSessionProps(document.cookie)
    }

    // check window context, wait for a moment
    await wait(0.2)

    if (window !== undefined && window?.nativeHeaders) {
      return getWebviewSessionPropsFromWindowCtx(
        window.nativeHeaders as NativeHeaders
      )
    }

    return getWebviewSessionPropsDefault()
  }

  useEffect(() => {
    const sessionCheck = async () => {
      try {
        const webviewSessionPropsData = await getWebviewSessionPropsBySource()

        if (webviewSessionProps.nativeHeaders?.Authorization) {
          setTokenCookies(webviewSessionProps.nativeHeaders.Authorization)
        }

        setWebviewSessionProps(webviewSessionPropsData)
        setLoading(false)
      } catch {
        // do nothing
      }
    }

    sessionCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    loading,
    webviewSessionProps,
  }
}

export default useWebViewSession
