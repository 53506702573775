import { useEffect } from 'react'
import { initDatadogLog } from '@astronautsid/wpe-utils'

const {
  REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_SITE,
  REACT_APP_DATADOG_SESSION_SAMPLE_RATE,
  REACT_APP_NODE_ENV,
  REACT_APP_VERSION,
  REACT_APP_NAME,
  REACT_APP_ENABLE_DATADOG,
} = process.env

const useAppInit = () => {
  useEffect(() => {
    if (REACT_APP_ENABLE_DATADOG === 'true') {
      initDatadogLog({
        clientToken: REACT_APP_DATADOG_CLIENT_TOKEN as string,
        site: REACT_APP_DATADOG_SITE,
        forwardErrorsToLogs: false,
        sessionSampleRate: Number(REACT_APP_DATADOG_SESSION_SAMPLE_RATE),
        env: REACT_APP_NODE_ENV === 'production' ? 'production' : 'development',
        service: REACT_APP_NAME,
        version: REACT_APP_VERSION,
      })
    }
  }, [])
}

export default useAppInit
