import { apiClient } from 'utils/api'
import { navigateToAdmin } from 'utils/navigateToAdminWeb'

import type { AuthSlice } from './authState'

export type AuthApi = {
  fetchTokenValidate: () => void
}

export const createAuthApi: StoreSlice<AuthApi, AuthSlice> = (set, get) => ({
  fetchTokenValidate: async () => {
    const { setIsAuthenticated, setUserData } = get()
    try {
      const res = await apiClient.get('/api/whoami')
      if (res.data.id) {
        setIsAuthenticated(true)
        setUserData(res.data)
      } else {
        navigateToAdmin()
      }
    } catch (err) {
      navigateToAdmin()
    }
  },
})
