import create from 'zustand'
import { withImmer, logger } from 'utils/store'
import {
  createLiveTrackingCrewSlice,
  type LiveTrackingCrewSlice,
} from './liveTrackingCrewSlice'

const useLiveTrackingCrewStore = create<LiveTrackingCrewSlice>(
  logger(withImmer(createLiveTrackingCrewSlice), 'MapStore')
)

export { useLiveTrackingCrewStore }
export type { LiveTrackingCrewSlice }
