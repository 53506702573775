import { Box, Text } from '@chakra-ui/react'
import { DeliveriesType } from 'pages/PoolingMap/type'

type OrderInfoContentPropsType = {
  delivery: DeliveriesType[number]
}
const OrderInfoContent = ({ delivery }: OrderInfoContentPropsType) => (
  <Box display="flex" flexDir="column" gap="12px">
    <Box
      display="flex"
      flexDir="row"
      gap="8px"
      width="100%"
      height="12px"
      alignItems="center"
    >
      <Box
        bgColor={delivery.color}
        width="12px"
        height="12px"
        borderRadius={50}
      />
      <Text fontSize="10px" fontWeight={400} lineHeight="12px">
        Pooling ID: {delivery.pool_id}
      </Text>
    </Box>
    <Box display="flex" flexDir="column" gap="4px">
      <Text fontSize="12px" fontWeight={400}>
        Tujuan
      </Text>
      <Text fontSize="14px" fontWeight={700}>
        {delivery.order_id}
      </Text>
      <Text fontSize="14px" fontWeight={700} color="rgba(100, 100, 100, 1)">
        {delivery.destination.receiver?.name}
      </Text>
    </Box>
    <Box display="flex" flexDir="row" gap="12px" flexWrap="wrap">
      <Box
        textAlign="center"
        bgColor="rgba(233, 233, 233, 1)"
        height="24px"
        padding="4px 12px"
        borderRadius="100px"
      >
        <Text
          fontSize="12px"
          fontWeight={600}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          Berat: {delivery.items.total_weight} kg
        </Text>
      </Box>
      <Box
        textAlign="center"
        bgColor="rgba(233, 233, 233, 1)"
        height="24px"
        padding="4px 12px"
        borderRadius="100px"
      >
        <Text
          fontSize="12px"
          fontWeight={600}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          Quantity: {delivery.items.total_qty}
        </Text>
      </Box>
      <Box
        textAlign="center"
        bgColor="rgba(233, 241, 252, 1)"
        height="24px"
        padding="4px 12px"
        borderRadius="100px"
        border="1px solid var(--primary-galaxy-8, rgba(36, 110, 229, 1))"
      >
        <Text
          fontSize="12px"
          fontWeight={600}
          color="rgba(32, 99, 206, 1)"
          whiteSpace="nowrap"
        >
          {delivery.delivery_option.timing_desc}
        </Text>
      </Box>
    </Box>
    <Box
      paddingTop="12px"
      paddingBottom="12px"
      display="flex"
      flexDir="column"
      borderTop="1px solid rgba(233, 233, 233, 1)
"
      borderBottom="1px solid rgba(233, 233, 233, 1)
"
    >
      <Text fontSize="10px" fontWeight={700}>
        Pin Point
      </Text>
      <Text fontSize="10px" fontWeight={400} color="rgba(100, 100, 100, 1)">
        {delivery.destination.address?.address}
      </Text>
    </Box>
    <Box display="flex" flexDir="column">
      <Text fontSize="10px" fontWeight={700}>
        Detail Alamat
      </Text>
      <Text fontSize="10px" fontWeight={400} color="rgba(100, 100, 100, 1)">
        {delivery.destination.address?.address}
      </Text>
    </Box>
  </Box>
)

export default OrderInfoContent
