import L from 'leaflet'
import markerBuyer from 'assets/images/png/marker_buyer.png'
import driverBuyer from 'assets/images/png/driver_buyer.png'
import markerActive from 'assets/images/png/marker_active.png'
import markerNonActive from 'assets/images/png/marker_nonactive.png'
import hub from 'assets/images/png/hub.png'
import driverAvailable from 'assets/images/png/driver_ready_for_delivery.png'
import driverOnDelivery from 'assets/images/png/driver_on_delivery.png'
import driverBackToHub from 'assets/images/png/driver_back_to_hub.png'
import { checkIsDriverBackToHub, checkIsDriverOnDelivery } from '../utils'

export const originActiveIcon = new L.Icon({
  iconUrl: markerActive,
  iconRetinaUrl: markerActive,
  popupAnchor: [-0, -0],
  iconSize: [56, 56],
})

export const originNonActiveIcon = new L.Icon({
  iconUrl: markerNonActive,
  iconRetinaUrl: markerNonActive,
  popupAnchor: [-0, -0],
  iconSize: [56, 56],
})

export const hubIcon = new L.Icon({
  iconUrl: hub,
  iconRetinaUrl: hub,
  popupAnchor: [-0, -0],
  iconSize: [38, 52],
})

export const markerBuyerIcon = new L.Icon({
  iconUrl: markerBuyer,
  iconRetinaUrl: markerBuyer,
  popupAnchor: [-0, -0],
  iconSize: [36, 41],
})

export const driverBuyerIcon = (iconSize: [number, number]) =>
  new L.Icon({
    iconUrl: driverBuyer,
    iconRetinaUrl: driverBuyer,
    popupAnchor: [-0, -0],
    iconSize,
  })

export const driverAvailableIcon = (iconSize: [number, number]) =>
  new L.Icon({
    iconUrl: driverAvailable,
    iconRetinaUrl: driverAvailable,
    popupAnchor: [-0, -0],
    iconSize,
  })

export const driverOnDeliveryIcon = (iconSize: [number, number]) =>
  new L.Icon({
    iconUrl: driverOnDelivery,
    iconRetinaUrl: driverOnDelivery,
    popupAnchor: [-0, -0],
    iconSize,
  })

export const driverBackToHubIcon = (iconSize: [number, number]) =>
  new L.Icon({
    iconUrl: driverBackToHub,
    iconRetinaUrl: driverBackToHub,
    popupAnchor: [-0, -0],
    iconSize,
  })

export const driverIcon = (
  key: string,
  zoom: number,
  isFromWebview?: boolean
) => {
  let iconSize: [number, number] = [44, 22]

  if (zoom > 18) {
    iconSize = [60, 28]
  }

  if (isFromWebview) return driverBuyerIcon(iconSize)

  if (checkIsDriverOnDelivery(key)) return driverOnDeliveryIcon(iconSize)
  if (checkIsDriverBackToHub(key)) return driverBackToHubIcon(iconSize)

  return driverAvailableIcon(iconSize)
}
