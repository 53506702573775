import './loadingMarker.css'
import { useEffect } from 'react'

import L, { LatLngExpression } from 'leaflet'
import { useMap } from 'react-leaflet'

type LoadingMarkerPropsType = {
  isLoading: boolean
  position: LatLngExpression
  children?: React.ReactNode
}

const LoadingMarker = ({
  isLoading,
  position,
  children,
}: LoadingMarkerPropsType) => {
  const map = useMap()

  useEffect(() => {
    if (isLoading) {
      const elementToRemove: HTMLElement | null =
        document.getElementById('marker-loading')
      if (elementToRemove) {
        elementToRemove.parentNode?.removeChild(elementToRemove)
      }

      const animatedCircleIcon = {
        icon: L.divIcon({
          className: 'marker-container',
          html: `
            <div id="marker-loading">
                <div class="circle" style="animation-delay: 0s"></div>
                <div class="circle whiteBorder" style="animation-delay: .5s"></div>
                <div class="circle" style="animation-delay: 2s"></div>
            </div>
            `,
          iconSize: [12, 22],
        }),
      }

      L.marker(position, animatedCircleIcon).addTo(map)
    }

    if (!isLoading) {
      const elementToRemove: HTMLElement | null =
        document.getElementById('marker-loading')
      if (elementToRemove) {
        elementToRemove.parentNode?.removeChild(elementToRemove)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, position])

  return <>{children}</>
}

export default LoadingMarker
