import axios from 'axios'
import { getTokenCookies } from 'utils/cookie'
import { toastErrResponse } from 'utils/toast'

export type GetFeatureFlagRoutingEngineResponse = {
  data: {
    app_version: string | null
    device: number
    features: {
      driver_routing_engine: boolean
    }
    location_id: number
  }
  error: {
    code: number
    message: string
    status: boolean
  }
}

export type FeatureFlagSlice = {
  features: {
    driver_routing_engine: boolean
  }
  getFeatureFlagRoutingEngine: (payload: {
    key: string
    auth: string
    locationId: number
  }) => void
}

export const createFeatureFlagSlice: StoreSlice<FeatureFlagSlice> = (set) => ({
  features: {
    driver_routing_engine: false,
  },
  getFeatureFlagRoutingEngine: async (payload) => {
    try {
      const { key, locationId, auth } = payload
      const token = getTokenCookies()

      const { data } = await axios.get<GetFeatureFlagRoutingEngineResponse>(
        `/api/feature-flag/${locationId}/1?featureKey=${key}`,
        {
          baseURL: process.env.REACT_APP_BASE_URL_API,
          headers: {
            Authorization: `Bearer ${auth || token}`,
          },
        }
      )

      set((state) => {
        state.features[key as never] = data.data.features[key as never]
      })
    } catch (err) {
      toastErrResponse(err, 'Gagal mendapatkan feature flag')
    }
  },
})
